
import { DEFAULT_KONVA_SCALE, DEFAULT_KONVA_STAGE_POSITION, IMAGERY_PROVIDER } from '../../../constants';
import { ToolNewPostionSilce } from './types';

const initializeState = ()=>({
	roofIndexes: [],
	selectedRoofIndex: null,
	deletedRoofIndexes: [],
	newlyCreatedFacets: {},
	editModeEnabled: false,
	threeDModelEnabled: false,
	saveWithPanelColor: true,
	userHasClickedSave: false,
	drawModeEnabled: false,
	isMouseOnMap: false,
	selectedUnfinalizedKonvaFacetId: null,
	deleteUnfinalizedFacet: false,
	zoomLevel: DEFAULT_KONVA_SCALE,
	moveStage: false,
	konvaStagePosition: DEFAULT_KONVA_STAGE_POSITION,
	userHasModifiedRoofSegs: false,
	fullScreenEnabled: false,
	selectedProvider: IMAGERY_PROVIDER.SUNROOF,
	hadTransition: false,
	deletedObstructionIDs: [],
	deleteObstructionsMode: false,
	currentActiveFacetEditMode: undefined,
	facetHistory: {currentIndex: 0, history: []},
	deleteObstructionsPopUp: false,
	toggleGroundMountEnabled: false,
	groundMountEnabled: false,
	editShadingPerformed: false,
});

function updateFacetHistory(facetHistory: ToolNewPostionSilce['facetHistory'], newState: ToolNewPostionSilce['facetHistory']['history'][0]){
	if (!facetHistory.currentIndex && !facetHistory.history.length) {
		facetHistory.history.push({ deletedObstructionIDs: []});
	}
	
	if (facetHistory.history[facetHistory.currentIndex]?.deletedObstructionIDs !== newState.deletedObstructionIDs){
			
		facetHistory.history = [
			...facetHistory.history.slice(0, facetHistory.currentIndex + 1),
			newState,
		];
		facetHistory.currentIndex = facetHistory.history.length - 1;
	}

	return facetHistory;
}

export {
	initializeState,
	updateFacetHistory
};

