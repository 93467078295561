import Popup from 'reactjs-popup';
import styled from 'styled-components';

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.15); /* Dark overlay background */
  }

  &-content {
    position: fixed !important;
    top: 0;
    right: 0;
    height: 100vh;
    width: 386px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%); /* Initially hide the modal off-screen to the right */
		border:none;
		padding: 1rem 0rem 1rem 1rem;
    &.open-content {
      transform: translateX(0); /* Slide in when open */
			background:#F4F5FB;
    }

    &.close-content {
      transform: translateX(100%); /* Slide out when closed */
    }
  }
`;


export { StyledPopup };

export const ModalContent = styled.div`
`; 

export const EditFacetModalBtnWrap = styled.div`
	
	button.edit-facet-btn{
		margin-top: 3px;
		margin-bottom: .5rem;
		background: #fff;
		width: 100%;
		color:#64686A;
		font-weight: 500;
		border: 1px solid #D6D6D6;
		font-size: 1rem;
		line-height: normal;
		cursor: pointer !important;
		padding: 0.75rem 2rem 0.65rem;
		border-radius: 2rem;
		gap:.65rem
	}
	`;

export const DivPanelCount = styled.div`
		 color: #64686A;
		font-family: 'Inter';
		font-weight: 500;
		font-size: 1rem;
		line-height: 1.25;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: .5rem;

		span{
		 font-size: 1rem;
		 font-weight: 600;
		 line-height: 17px;
		 padding-left: 3px;
		 position: relative;
     top: 0px;
		 color: #28373E;
		}
	`;

export const AzimuthHeading = styled.h4`
		font-family: 'Inter';
    font-weight: 500;
    font-size: 1rem;
    color: #2E384D;
    margin: 0 0px .5rem;
    line-height: 1.2;
    font-size: 1.125rem;

		sup{
			font-size: 65%;
			opacity:1;
		}
		span {
			margin-left: 5px;
			color: #28373E;
			font-family: 'Inter';
			font-weight: 400;
			font-size: 1rem;
		}
	`;

export const DivPitchWrap = styled.div`
		color: #64686A;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: .5rem;

		span{
			font-size: 1rem;
			line-height: 17px;
			font-weight: 600;
			padding-left: 2px;
			position: relative;
			top: 0px;
			color: #28373E
		}
			sup{
				opacity:.85;
			}
`;

export const DivFacetDetail = styled.div`
	padding: 1.125rem .85rem;
	background: #fff;
	box-shadow: none;
	border: 1px solid transparent;
	border-radius: 0px;
	margin-bottom: .815rem;
		&.active {
				background: #e3ebff;
		}
`;

export const ModalFacetWrap = styled.div`
width: 100%;

`;
export const FacetWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	padding-right: 1rem;
`;

export const ModalFacetBox = styled.div`
		padding: 1.125rem .85rem;
    background: #fff;
    box-shadow: none;
    border: none;
    border-radius: 0px;
    margin-bottom: .815rem;
	
		&.active {
		background: #E5EBFF;

			button{
				background: #fff;
			}
		}
`;

export const ModalFacetInfoWrap = styled.div`
		overflow-y: auto;
		height: calc(100vh - 100px);
    padding-right: .5rem;
`;
export const FacetTitle = styled.div`
		color: #28373E;
		font-size: 1.25rem;
		font-family: 'Inter';
		font-weight: 500;
`;

export const HeadingAzimuth = styled.div`
		font-family: 'Inter';
    font-weight: 500;
    color: #2E384D;
    margin: 0 0px .5rem;
    line-height: 1.2;
    font-size: 1.125rem;
		span{
			padding-left: 2px;
			font-weight: 400;
			font-size: 1rem;
		}
		sup{
			font-size:.65rem
			font-weight: 400;
		}
`;

export const ShadingWrap = styled.div`

 span {
    color: #64686A;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25;
    display: block;
		padding-bottom: 6px;
  }	
	button {
    box-shadow: none;
    border: 1px solid #EFF0F7;
    background: #EFF0F7;
    color: #64686A;
    font-size: 0.875rem;
    text-transform: capitalize;
    line-height: 1.358;
    padding: 5px .85rem 4px .85rem;
		border-radius: 4px;
		font-family: 'Inter';
    font-weight: 500;

		&.active {
				border-color: var(--primary);
				background: #fff;
				color: var(--primary);
		}
	}

	&:not(facet-slope-wrap) {
    margin-bottom: 10px;
	}
`;
