import { NewlyCreatedFacet } from 'store/slices/ToolSlice/types';
import { ToggleButton, ToggleGroup } from 'components/Basic/ToggleGroup';
import { toggleManualToolDoneButtonState, toggleManualToolResetButtonState } from 'store/slices/ManualToolModal';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { ModalFacetBox, HeadingAzimuth, ShadingWrap } from './style';
import { DEFAULT_SHADING_VAL, KONVA_FACET_GROUP } from '../../constants';
import PitchSlider from './PitchSlider';


function AzimuthDiv(props: { azimuth: number }) {
	const roundedValue = props.azimuth ? Math.round(props.azimuth) : 'None';
	return (
		<HeadingAzimuth>
			Azimuth: 
			<span>{roundedValue}</span>
		</HeadingAzimuth >
	);
}

type Props = NewlyCreatedFacet & {
	groupSuffix: string;
	active?: boolean;
	onChange: (id: string, groupSuffix: string) => void;
	onShadingValChange(id: string, groupSuffix: string, value: any): void;
	onPitchValChange(id: string, groupSuffix: string, value: any): void;
};
export default function NonDefaultModeValues(props: Props) {
	const { azimuthDegrees, pitchDegrees, shading = DEFAULT_SHADING_VAL, isGroundMount, groupSuffix } = props;
	const dispatch = useDispatch<AppDispatch>();
	function handleClick(){
		props.onChange(props.konvaPolygonID, props.groupSuffix);
	}

	return (
		<>
			{
				<ModalFacetBox className={`${props.active? ' active': ''}`} onClick={handleClick}>
					{/* <div className="facet-label_name">Facet A</div> */}
					<AzimuthDiv azimuth={azimuthDegrees!} />

					<div className='facet-slider-wrap facet-slider-slope-info'>
						{
							<PitchSlider 
								pitch={pitchDegrees ?? (isGroundMount ? 18 : 20)}
								active= {!!props.active }
								onChange={(v) => {
									props.onPitchValChange(props.konvaPolygonID, props.groupSuffix, +v);
									props.onChange(props.konvaPolygonID, props.groupSuffix);
									dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: true }));
									if(groupSuffix.slice(1) != KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME){
										dispatch(toggleManualToolResetButtonState({resetEnabled: true}));
									}
								}}
							/>
						}
					</div>
					<ShadingWrap className='facet-slope-wrap facet-slider-slope-info'>
						<span>Shading:</span>
						<ToggleGroup
							defaultValue={shading.toString()}
							onChange={(v) => {
								props.onShadingValChange(props.konvaPolygonID, props.groupSuffix, +v);
								props.onChange(props.konvaPolygonID, props.groupSuffix);
								dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: true }));
							}}
						>
							<ToggleButton value="14.08">None</ToggleButton>
							<ToggleButton value="15.85">low</ToggleButton>
							<ToggleButton value="20.28">medium</ToggleButton>
							<ToggleButton value="24.76">high</ToggleButton>
						</ToggleGroup>
					</ShadingWrap>
				</ModalFacetBox>
			}
		</>
	);

}
