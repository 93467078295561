import styled from 'styled-components';

export const SliderInput = styled.input`
	background: linear-gradient(to right, #ff932a 0%, rgb(255 147 42) 67.9245%, #a1a1a1 67.9245%, #a1a1a1 100%);
	width: 100%;
	height: 15px;
	border-radius: 16px;  
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s; 
	-webkit-appearance: none;
	 
	@media (max-width: 1315px){
		height:12px;
	}

	&::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 50%; 
  background: #fff;
  cursor: pointer;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
}

	&::-moz-range-thumb {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #fff;
  cursor: e-resize;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px;
	background: #434343;
}

&[type="range"] {
	margin: 0 !important;
}

@media (max-width: 768px) {
	width: 100%;
}
	@media (max-width: 480px) {
	width: 100%;
}

`;

export const SliderWrap = styled.div`
	// background: #f2f2f2;
	padding: 2px 2px 3px 2px;
	border-radius: 16px;
	margin-bottom: 0px;
	position:relative;
	width: 100%;
	top: 2.5px;
	max-width: 320px;
	@media (max-width: 840px) {
	max-width: 100%;
}
`;

export const TotalPanel = styled.span` 
		color: #4A525A;
    font-weight: 600;
		font-size:1.063rem;

		@media (max-width: 1325px) {
		font-size:1rem;
	}
`;

export const PitchWrap = styled.div` 
		color: #64686A;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25;
    display: flex;
    justify-content: flex-start;
    align-items: center;

		.pitch-degree{
			font-size: 94%;
			font-weight: 400;
			padding-left: 2px;
			position: relative;
			top: -1px;
		}
`;

