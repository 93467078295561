import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { toggleRecalculateLayoutModalState } from 'store/slices/EditFacetModalSlice';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function RecalculateLayoutButton(){

	const { selectedRoofIndex, currentActiveFacetEditMode, roofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const isEditPanelModeActive = useSelector((state: RootState) => state.panelSlice.data.activePanelMode);

	const queriesRunningInBackGround = useQueriesRunningInBackGround();
	const recalculateLayoutQueryRunning = useQueriesRunningInBackGround(['recalculateLayout']);
	
	const disableRecalculateWhenAnyModeIsActiveOrFacetMoved = !!currentActiveFacetEditMode || !!roofIndexes.length || isEditPanelModeActive;

	const dispatch = useDispatch();

	return (
		<Button
			id="recalculate-layout"
			className="btn-tools"
			style={{
				background: '#fff',
			}} 
			onClick={() => dispatch(toggleRecalculateLayoutModalState({ value: true }))}
			disabled={!selectedRoofIndex || queriesRunningInBackGround || disableRecalculateWhenAnyModeIsActiveOrFacetMoved}
			tooltipContent='Recalculate Layout'
			showLoader={!!recalculateLayoutQueryRunning}>
			<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17.6334 22.9998C20.5969 22.9998 22.9993 20.5974 22.9993 17.6339C22.9993 14.6704 20.5969 12.2681 17.6334 12.2681C14.67 12.2681 12.2676 14.6704 12.2676 17.6339C12.2676 20.5974 14.67 22.9998 17.6334 22.9998Z" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M18.9224 15.2195L19.4134 15.8473C19.6962 16.2084 19.6441 16.3168 19.1891 16.3168H16.3468C15.5623 16.3168 15.4883 16.7289 15.4883 17.4147M16.3468 20.0488L15.8558 19.4209C15.5736 19.0598 15.6251 18.9509 16.0801 18.9509H18.9224C19.7064 18.9509 19.781 18.5393 19.781 17.8536" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M10.6585 20.3171H4C2.34315 20.3171 1 18.9739 1 17.3171V4C1 2.34315 2.34315 1 4 1H17.3171C18.9739 1 20.3171 2.34315 20.3171 4V10.6585" stroke="#64686A" strokeWidth="1.6"/>
				<path d="M6.08203 3.68262V8.48235" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M8.48242 6.08252L3.68269 6.08252" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M7.81445 13.4055L4.42053 16.7994" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M7.81445 16.7993L4.42053 13.4054" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M17.0957 5.83667L12.296 5.83667" stroke="#64686A" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
		</Button>
	);
}