
import { useCallback, useMemo, useRef, useState } from 'react';
import {
	StyledPopup,
	ModalContent,
	EditFacetModalBtnWrap,
	ModalFacetWrap,
	FacetWrapper,
	ModalFacetInfoWrap,
	FacetTitle
} from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import NonDefaultModeValues from './NonDefaultModeValues';
import DefaultModeValues from './DefaultModeValues';
import { useRefs } from 'contexts/RefContext';
import Konva from 'konva';
import { debounce, getDeratedValue } from '../../util';
import { createNewFacets, translatePanels } from './api';
import { getAbsolutePointsOfTranslatedPanels } from './util';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { deleteAllFacetsFromPanelDrawer, disableDraggingInKonvaGroups } from 'components/tool/util';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { KONVA_FACET_GROUP, DESIGN_MODES, DEFAULT_SHADING_VAL } from '../../constants';
import { transformPointsToOriginalScale } from 'components/DisplayEnergy/util';
import {
	setAzimuthOrPitchForNewlyCreatedNrelFacets,
	setDeletedRoofIndexes,
	setEditState,
	setRoofIndexes,
	setUserModificationState
} from 'store/slices/ToolSlice';
import { toggleManualToolDoneButtonState, toggleManualToolModalState, toggleManualToolResetButtonState } from 'store/slices/ManualToolModal';
import { NewlyCreatedFacet } from 'store/slices/ToolSlice/types';
import Button from 'components/Basic/Button';
import { EnergyState } from 'store/slices/EnergySlice/types';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { toggleRecalculateLayoutModalModalSliceState } from 'store/slices/RecalculateLayoutSlice';

const highlightTimer = 5000;

const SideModal = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { newlyCreatedFacets, roofIndexes, deletedRoofIndexes, selectedRoofIndex,
		selectedUnfinalizedKonvaFacetId, drawModeEnabled, editModeEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { open: manualToolModalOpen, showAllFacets, inputFieldsEdited, resetEnabled } =
		useSelector((state: RootState) => state.manualToolModal.data);
	const { uuid, allRoofSegs, panel, mode, roofSegsFromWhichUserHasSelectedPanels } = useSelector((state: RootState) => state.roofData.data);
	const { selectedPanels: selectedPanelsOnUI, deratedBy } = useSelector(
		(state: RootState) => state.energyData.data as EnergyState
	);
	const { konvaRef } = useRefs();
	const prevSelectedGrp = useRef<string | null>(null);
	const timeOut = useRef<NodeJS.Timeout>();
	const scale = useKonvaScale();
	const notDefaultMode = mode !== DESIGN_MODES.DEFAULT;

	const [selectedFacetID, setSelectedFacetID] = useState<string | null>(null);
	const [resetToLoaded, setResetToLoaded] = useState<boolean>(false);
	const selectedCombinedFacetID = selectedUnfinalizedKonvaFacetId || selectedRoofIndex;

	const highlightGroup = useCallback((id: string, groupSuffix: string) => {
		setSelectedFacetID(id);
		if (!konvaRef?.current) return;

		// if user clicks on diff group then prev group and the pre grp is still highlighted
		if (prevSelectedGrp.current !== id) {
			clearTimeout(timeOut.current);
			const group: Konva.Group = konvaRef?.current?.findOne('#' + prevSelectedGrp.current + groupSuffix);
			group?.clearCache();
		}

		prevSelectedGrp.current = id;

		const group: Konva.Group = konvaRef?.current?.findOne('#' + prevSelectedGrp.current + groupSuffix);
		group?.cache();
		group?.filters([Konva.Filters.RGB]);

		group?.red(0);
		group?.green(0);
		group?.blue(255);

		console.log('ggg', group);

		clearTimeout(timeOut.current);
		timeOut.current = setTimeout(() => {
			group?.clearCache();
		}, highlightTimer);
	}, [konvaRef]);

	const debouncePitchDispatchForUserDefinedFacets = debounce((id: string, groupSuffix: string, value: number,) => {
		dispatch(setAzimuthOrPitchForNewlyCreatedNrelFacets({
			facetId: id,
			pitchDegrees: value,
		}));
	}, 200);

	const debounceShadingDispatchForUserDefinedFacets = debounce((id: string, groupSuffix: string, value: number) => {
		dispatch(setAzimuthOrPitchForNewlyCreatedNrelFacets({
			facetId: id,
			shading: value
		}));
	}, 200);

	const debounceShadingForSystemDefinedFacets = debounce((id: string, groupSuffix: string, value: number) => {
		const group: Konva.Group | undefined = konvaRef?.current?.findOne('#' + id + groupSuffix);
		group?.setAttr('shading', value);
		if (!roofIndexes.includes(id))
			dispatch(setRoofIndexes({ roofIndexes: roofIndexes.concat(id) }));
	}, 200);

	const debouncePitchForSystemDefinedFacets = debounce((id: string, groupSuffix: string, value: number) => {
		const group: Konva.Group | undefined = konvaRef?.current?.findOne('#' + id + groupSuffix);
		group?.setAttr('pitch', value);
		if (!roofIndexes.includes(id))
			dispatch(setRoofIndexes({ roofIndexes: roofIndexes.concat(id) }));
	}, 200);

	// const debounceDispatch = debounce(
	// 	(id: string, obj: { [K in keyof NewlyCreatedFacet]: { key: K; value: NewlyCreatedFacet[K] } }[keyof NewlyCreatedFacet]) => {
	// 		if(!obj?.key || !obj?.value) return;
	// 		dispatch(setAzimuthOrPitchForNewlyCreatedNrelFacets({
	// 			facetId: id,
	// 			[obj?.key]: obj?.value
	// 		}));
	// 	}, 100);

	const selectedRoofSegs = useMemo(() => {
		if (editModeEnabled) return allRoofSegs.filter(r => r.panelsCount > 0);
		return allRoofSegs.filter((slcRoofSeg) => roofSegsFromWhichUserHasSelectedPanels.includes(slcRoofSeg.id));
	}, [editModeEnabled, allRoofSegs, roofSegsFromWhichUserHasSelectedPanels]);

	function disbaleEditMode() {
		dispatch(setRoofIndexes({ roofIndexes: [] }));
		// dispatch(setEditState({ enabled: false }));
		if (konvaRef?.current) {
			(konvaRef.current?.findOne('Transformer') as Konva.Transformer).setNodes([]);
			disableDraggingInKonvaGroups(konvaRef?.current.find('.' + KONVA_FACET_GROUP.EXISTING_GROUP_NAME));
		}
	}

	function getNewFacets() {
		// todo confirm when we only have deleted indexes
		if ((!Object.keys(newlyCreatedFacets).length && !deletedRoofIndexes.length)) {
			return;
		}

		const transformedFacets = transformFacets();
		return {
			transformedFacets,
			deletedRoofIndexes
		};
	}

	function transformFacets() {
		if (mode !== DESIGN_MODES.DEFAULT) {
			return Object.values(newlyCreatedFacets).map(facet => ({
				hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale, coord))),
				azimuthDegrees: facet.azimuthDegrees,
				pitchDegrees: facet.pitchDegrees ? +facet.pitchDegrees : facet?.isGroundMount ? 18 : 20,
				shading: facet.shading ? +facet.shading : DEFAULT_SHADING_VAL,
				isGroundMount: facet?.isGroundMount,
			}));
		}

		return Object.values(newlyCreatedFacets).map(facet => ({
			hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale, coord))),
			isGroundMount: facet?.isGroundMount,
		}));
	}

	const translatePanelPostionsQuery = useQuery(
		'translate-panels-nrel',
		async () => {
			if (!konvaRef?.current) return;
			const newTranslatedPostions = getAbsolutePointsOfTranslatedPanels(konvaRef.current, roofIndexes, allRoofSegs, scale);
			if (!newTranslatedPostions.length) throw new Error('E_NO_TRANSLATIONS_TO_SAVE');
			// return;
			await translatePanels(newTranslatedPostions, panel.key, uuid);
		},
		{
			onError: (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE') return;
				toast.error('something went wrong, please try again while edting facets, plz try again');
			},
			retry: (failureCount, error: Error) => {
				return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE' && failureCount < 2;
			},
			enabled: false,
		}
	);

	const createNewFacetsQuery = useQuery(
		'create-new-facets-nrel',
		async () => {
			const newFacets = getNewFacets();
			if (!newFacets) return;
			console.log('creating new facets');
			await createNewFacets({
				newPanelFacets: newFacets.transformedFacets,
				deletedRoofSegs: deletedRoofIndexes,
			}, panel.key, uuid);
		},
		{
			onError: (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE') return;
				toast.error('something went wrong, please try again while creating facets');
			},
			// retry: (failureCount, error: Error) => {
			// 	return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE';
			// },
			retry: 0,
			enabled: false,
		}
	);

	function deleteFacets() {
		if (!konvaRef?.current) return;
		deleteAllFacetsFromPanelDrawer(konvaRef.current);
	}

	const handleQueiresSuccess = async () => {
		disbaleEditMode();
		await dispatch(changePanel({ uuid, panelKey: panel.key }));
		dispatch(setDeletedRoofIndexes({ deletedRoofIndexes: [] }));
		dispatch(setUserModificationState({ hasMadeChanges: true }));
		deleteFacets();
	};

	const handleQueries = async () => {
		try {
			await Promise.all([translatePanelPostionsQuery.refetch(), createNewFacetsQuery.refetch()]);
			await handleQueiresSuccess();
			dispatch(toggleManualToolModalState({ value: false }));
			dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: false }));
			dispatch(toggleManualToolResetButtonState({ resetEnabled: false }));
		} catch (error) {
			console.error('Error while executing queries:', error);
		}
	};

	const getUserDefinedFacet = useCallback((newlyCreatedFacet: NewlyCreatedFacet) => {

		const isActive = !selectedFacetID ?
			selectedCombinedFacetID === newlyCreatedFacet.konvaPolygonID : selectedFacetID === newlyCreatedFacet.konvaPolygonID;
		return {
			...newlyCreatedFacet,
			active: isActive,
			groupSuffix: KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME,
			onChange: highlightGroup,
			onPitchValChange: debouncePitchDispatchForUserDefinedFacets,
			onShadingValChange: debounceShadingDispatchForUserDefinedFacets,
		};

	}, [
		debouncePitchDispatchForUserDefinedFacets,
		debounceShadingDispatchForUserDefinedFacets,
		highlightGroup, selectedCombinedFacetID, selectedFacetID
	]);

	const getSystemGerneratedFacetForNonDefaultMode = useCallback((facet: RasterRoofSegment) => {
		const groupSuffix = KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.EXISTING_GROUP_NAME;
		const group: Konva.Group | undefined = konvaRef?.current?.findOne('#' + facet.id + groupSuffix);
		const isActive = !selectedFacetID ? facet.id === selectedCombinedFacetID : facet.id === selectedFacetID;
		const { totalSelectedPanelsFromFacet, totalSelectedPanelsProduction } = facet.panels.reduce((sum, panel) => ({
			totalSelectedPanelsFromFacet: sum.totalSelectedPanelsFromFacet + (selectedPanelsOnUI.includes(panel.id) ? 1 : 0),
			totalSelectedPanelsProduction: sum.totalSelectedPanelsProduction + (selectedPanelsOnUI.includes(panel.id) ? panel.yearlyEnergyDcKwh : 0)
		}), { totalSelectedPanelsFromFacet: 0, totalSelectedPanelsProduction: 0 });
		return {
			hullCoords: [],
			konvaPolygonID: facet.id,
			azimuthDegrees: facet.azimuthDegrees,
			pitchDegrees: group?.attrs.pitch ?? facet.pitchDegrees,
			shading: group?.attrs.shading ?? facet.shading,
			previousPitch: facet.pitchDegrees,
			previousShading: facet.shading,
			panelsCount: totalSelectedPanelsFromFacet,
			systemProduction: getDeratedValue(totalSelectedPanelsProduction, deratedBy),
			active: isActive,
			groupSuffix,
			isGroundMount: !!facet?.isGroundMount,
			onChange: highlightGroup,
			onPitchValChange: debouncePitchForSystemDefinedFacets,
			onShadingValChange: debounceShadingForSystemDefinedFacets,
		};
	},[debouncePitchForSystemDefinedFacets, debounceShadingForSystemDefinedFacets, deratedBy, highlightGroup, konvaRef, 
		selectedCombinedFacetID, selectedFacetID, selectedPanelsOnUI]);

	const combinedObjsForNonDefaultMode = useMemo(() => {
		return [
			...Object.keys(newlyCreatedFacets).map(k => {
				const obj: React.ComponentProps<typeof NonDefaultModeValues> = getUserDefinedFacet(newlyCreatedFacets[k]);
				return obj;
			}),
			...selectedRoofSegs.map(o => getSystemGerneratedFacetForNonDefaultMode(o))
		];
	}, [newlyCreatedFacets, selectedRoofSegs, getUserDefinedFacet, getSystemGerneratedFacetForNonDefaultMode]);

	const selectedFacetDetailsForNonDefaultMode = useMemo(() => {
		if (selectedUnfinalizedKonvaFacetId) {
			const selectedUnfinalizedFacet = newlyCreatedFacets[selectedUnfinalizedKonvaFacetId];
			if (selectedUnfinalizedFacet) return getUserDefinedFacet(selectedUnfinalizedFacet);
		}

		if (selectedRoofIndex) {
			const selectedFacet = allRoofSegs.find((s) => s.id === selectedRoofIndex);
			if (selectedFacet) return getSystemGerneratedFacetForNonDefaultMode(selectedFacet);
		}

		return null;
	}, [
		selectedRoofIndex, 
		selectedUnfinalizedKonvaFacetId, 
		allRoofSegs, 
		getSystemGerneratedFacetForNonDefaultMode, 
		newlyCreatedFacets, 
		getUserDefinedFacet
	]);

	const getSystemGerneratedFacetForDefaultMode = useCallback((facet: RasterRoofSegment): React.ComponentProps<typeof DefaultModeValues> => {
		const groupSuffix = KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR + KONVA_FACET_GROUP.EXISTING_GROUP_NAME;
		const group: Konva.Group | undefined = konvaRef?.current?.findOne('#' + facet.id + groupSuffix);
		const isActive = !selectedFacetID ? facet.id === selectedCombinedFacetID : facet.id === selectedFacetID;
		const { totalSelectedPanelsFromFacet, totalSelectedPanelsProduction } = facet.panels.reduce((sum, panel) => ({
			totalSelectedPanelsFromFacet: sum.totalSelectedPanelsFromFacet + (selectedPanelsOnUI.includes(panel.id) ? 1 : 0),
			totalSelectedPanelsProduction: sum.totalSelectedPanelsProduction + (selectedPanelsOnUI.includes(panel.id) ? panel.yearlyEnergyDcKwh : 0)
		}), { totalSelectedPanelsFromFacet: 0, totalSelectedPanelsProduction: 0 });

		return {
			konvaPolygonId: facet.id,
			azimuthDegrees: facet.azimuthDegrees,
			pitchDegrees: group?.attrs.pitch || facet.pitchDegrees,
			active: isActive,
			groupSuffix,
			onChange: highlightGroup,
			systemProduction: getDeratedValue(totalSelectedPanelsProduction, deratedBy),
			panelsCount: totalSelectedPanelsFromFacet,
			systemSize: totalSelectedPanelsFromFacet * panel.wattage,
			previousPitch: facet.pitchDegrees,
			isGroundMount: !!facet?.isGroundMount,
			onPitchValChange: facet?.isGroundMount ? debouncePitchForSystemDefinedFacets : undefined,
		};
	}, [debouncePitchForSystemDefinedFacets, deratedBy, highlightGroup, konvaRef, panel.wattage, 
		selectedCombinedFacetID, selectedFacetID, selectedPanelsOnUI]);

	const combinedObjsForDefaultMode = useMemo(() => {
		return [
			...selectedRoofSegs.map(o => getSystemGerneratedFacetForDefaultMode(o))
		];
	}, [selectedRoofSegs, getSystemGerneratedFacetForDefaultMode]);

	const selectedFacetDetailsForDefaultMode = useMemo(() => {
		const selectedFacet = allRoofSegs.find((s) => s.id === selectedRoofIndex);
		if (selectedFacet) return getSystemGerneratedFacetForDefaultMode(selectedFacet);

		return null;
	}, [allRoofSegs, getSystemGerneratedFacetForDefaultMode, selectedRoofIndex]);

	function FacetDetailsForNonDefaultMode() {
		if (showAllFacets) {
			return (
				<>
					{combinedObjsForNonDefaultMode.map((k) => (
						<NonDefaultModeValues
							key={k.konvaPolygonID}
							{...k}
						/>
					))}
				</>
			);
		}

		if (selectedFacetDetailsForNonDefaultMode) {
			return <NonDefaultModeValues {...{
				...selectedFacetDetailsForNonDefaultMode,
				active: true,
				enablePitchEdit: mode !== DESIGN_MODES.DEFAULT,
				enableShadingEdit: mode !== DESIGN_MODES.DEFAULT,
			}} />;
		}

		return null;
	}

	function FacetDetailsForDefaultMode() {
		if (showAllFacets) {
			return (
				<>
					{combinedObjsForDefaultMode.map((k) => (
						<DefaultModeValues
							key={k.konvaPolygonId}
							{...k}
						/>
					))}
				</>
			);
		}

		if (selectedFacetDetailsForDefaultMode) {
			return <DefaultModeValues {...{
				...selectedFacetDetailsForDefaultMode,
			}} />;
		}

		return null;
	}

	const createNewFacetsQueryOrtranslatePanelPostionsQueryRunning =
		useQueriesRunningInBackGround(['create-new-facets-nrel', 'translate-panels-nrel']);

	function resetToLoadedState() {
		const facetDetails: { [key: string]: { previousPitch: number, previousShading?: number, previousAzimuth: number } } = {};
		combinedObjsForNonDefaultMode.forEach((facet) => {
			if ('previousPitch' in facet && facet.previousPitch !== undefined &&
				'previousShading' in facet && facet.previousShading !== undefined &&
				'azimuthDegrees' in facet && facet.azimuthDegrees !== undefined) {

				facetDetails[facet.konvaPolygonID] = {
					previousPitch: facet.previousPitch,
					previousShading: facet.previousShading,
					previousAzimuth: facet.azimuthDegrees,
				};
			}
		});
		combinedObjsForDefaultMode.forEach((facet) => {
			if ('previousPitch' in facet && facet.previousPitch !== undefined &&
				'azimuthDegrees' in facet && facet.azimuthDegrees !== undefined) {

				facetDetails[facet.konvaPolygonId] = {
					previousPitch: facet.previousPitch,
					previousAzimuth: facet.azimuthDegrees,
				};
			}
		});
		const groups = konvaRef?.current?.
			find('.' + KONVA_FACET_GROUP.EXISTING_GROUP_NAME).
			filter(g => roofIndexes.
				includes(g.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0])) as Konva.Group[];
		groups?.forEach((g) => {
			const facetKey = g.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];
			const prevVals = facetDetails[facetKey];
			if (prevVals) {
				g.setAttr('pitch', prevVals.previousPitch);
				g.setAttr('shading', prevVals.previousShading);
				g.setAttr('azimuth', prevVals.previousAzimuth);

				// Set rotation
				if (g.rotation()) {
					g.rotation(prevVals.previousAzimuth);
					g.position({ x: 0, y: 0 });
					g.skew({ x: 0, y: 0 });
				}
			}
		});
		disbaleEditMode();
		setResetToLoaded(!resetToLoaded);
		dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: false }));
		dispatch(toggleManualToolResetButtonState({ resetEnabled: false }));
	}

	return (
		<EditFacetModalBtnWrap>
			<Button
				className="open-button edit-facet-btn"
				onClick={
					() => dispatch(toggleManualToolModalState({ value: true, showAll: true }))
				}>
				Facet List

				<svg width="17" height="18" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M11 7H17" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M11 13H17" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M11 2C11 1.44772 11.4477 1 12 1H15.046H16C16.5523 1 17 1.44772 17 2V17C17 17.5523 16.5523 18 
					16 18H15.046H12C11.4477 18 11 17.5523 11 17V2Z" stroke="#64686A" strokeWidth="1.5" />
					<path d="M1 2C1 1.44772 1.44772 1 2 1H11.7893H16C16.5523 1 17 1.44772 17 2V17C17 17.5523 16.5523 18 16 
					18H11.7893H2C1.44771 18 1 17.5523 1 17V2Z" stroke="#64686A" strokeWidth="1.5" />
				</svg>
			</Button>
			<StyledPopup
				open={manualToolModalOpen}
				closeOnDocumentClick
				onClose={
					() => {
						dispatch(toggleManualToolModalState({ value: false }));
					}
				}
				className={manualToolModalOpen ? 'open' : 'close'}
			>
				<div className="popup-modal-wrap">

					<ModalContent className="modal-content">
						<ModalFacetWrap>
							<FacetWrapper>
								<FacetTitle>
									Facet Details:
								</FacetTitle>
								<div style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-end',
									gap: '.5rem'
								}}>
									{(resetEnabled) && (<Button onClick={async () => {
										resetToLoadedState();
									}
									}
									style={{
										background: '#fff',
										color: '#64686A',
										border: '1px solid rgba(0,0,0,.20)'
									}}>
										<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M8.5 10.4853H11.1069M13.1123 8.94116V10.4853M13.1123 12.0294V10.4853M13.1123 10.4853H14.6764" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
											<path d="M14.6758 14.456H12.0688M10.0635 12.9119V14.456M10.0635 16.0001V14.456M10.0635 14.456H8.49933" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
											-	<path d="M5.7317 13.5996C3.01246 12.8991 1 10.3877 1 7.39704C1 3.86405 3.80845 1 7.27284 1C10.2025 1 12.6631 3.0482 13.3529 5.81707M5.7317 13.5996V10.8863M5.7317 13.5996V13.7941H2.71077" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
										</svg>
										Reset
									</Button>)}
									{((notDefaultMode && (inputFieldsEdited || drawModeEnabled)) ||
									(!notDefaultMode && inputFieldsEdited)) && (
										<Button
											showLoader={createNewFacetsQueryOrtranslatePanelPostionsQueryRunning}
											disabled={createNewFacetsQueryOrtranslatePanelPostionsQueryRunning}
											onClick={async () => {
												let showResetLayoutMessage = false;
												const groups = konvaRef?.current?.
													find('.' + KONVA_FACET_GROUP.EXISTING_GROUP_NAME).
													filter(g => roofIndexes.
														includes(g.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0])) as Konva.Group[];
												groups?.forEach((g) => {
													const segmentId = g.attrs.id.split(KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR)[0];
													const roofSegment = allRoofSegs.find(rs => rs.id === segmentId);
													const newPitch = g.getAttr('pitch');
													if ((newPitch || newPitch == 0) && newPitch != roofSegment?.pitchDegrees) {
														showResetLayoutMessage = true;
													}
												});
												if (showResetLayoutMessage) {
													dispatch(toggleRecalculateLayoutModalModalSliceState({
														open: true,
														warningMessage: 'Roof Segments for which pitch is changed, layout will be recalculated.',
													}));
												} else {
													await handleQueries();
												}
											}}
										>
											Done
										</Button>
									)}
								</div>
							</FacetWrapper>
							<ModalFacetInfoWrap className="modal-facet-info-wrap">
								{
									mode === DESIGN_MODES.DEFAULT ?
										<FacetDetailsForDefaultMode />
										:
										<FacetDetailsForNonDefaultMode />
								}
							</ModalFacetInfoWrap>
							{/* Add more content here */}
						</ModalFacetWrap>
					</ModalContent>
				</div>
			</StyledPopup >
		</EditFacetModalBtnWrap>
	);
};

export default SideModal;

