/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Button from '../../components/Basic/Button';
import { useLocation } from 'react-router-dom';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { createNewDesign as createNewDesignReq } from './api';
import Loader from '../../components/Loader';
import ROUTES from '../../constants/routes';
import { Mapwrap, MapHeader } from './styles';
import { PARENT_EVENTS, SOlAR_QOUTE_APP_NAME, SUBHUB_APP_NAME } from '../../constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { fetchRoofData } from 'store/slices/RoofDataSlice/roofDataSlice';
import { decideDerateBasedUponMode, removeFalsyValueFromObj } from '../../util';
import { convertObjValsToNum } from '../../util';
import { setEnergyDerate, setQueryParamState } from 'store/slices/QueryParamsSlice';
import { useOrgTheme } from 'contexts/ThemeContext';
import useNavigateWithQueryParams from 'hooks/useNavigateWithQueryParams';

const containerStyle = {
	width: '100%',
	height: '100%',
};

function LocationMap() {
	const { navigateWithQueryParams } = useNavigateWithQueryParams();
	const data: any = useLocation();
	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyDf2acZiqNzq9ZJiwVAm0k5xAvIW-QLiEM',
	});

	const [map, setMap] = useState<google.maps.Map>();
	const [center] = useState(data?.state?.coordinates);
	const queryParams = useSelector((state: RootState) => state.QueryParams.data);
	const dispatch = useDispatch<AppDispatch>();
	const [markerPosition, setMarkerPosition] = useState(center);
	const { mapMarkerColor, selectHouseText, runAiBtnText, mainHeading } = useOrgTheme();

	const size = 10;
	const plusSymbol = {
		path: `M 0,-${size} 0,${size} M -${size},0 ${size},0`,
		strokeColor: `${mapMarkerColor}`,
		fillOpacity: 2,
		scale: 2
	};

	useEffect(() => {
		if (map) {
			const listener = map.addListener('center_changed', () => {
				setMarkerPosition({
					lat: map.getCenter()?.lat(),
					lng: map.getCenter()?.lng(),
				});
			});
			return () => {
				google.maps.event.removeListener(listener);
			};
		}
	}, [map]);

	useEffect(() => {
		function changeBillInputs(event: MessageEvent) {
			const iframeIsEmbeddedInSubhub = queryParams.parentAppName === SUBHUB_APP_NAME;
			// console.log('eventt', event);
			if (event.data?.eventType !== PARENT_EVENTS.ENERGY_INPUTS_CHANGED || !iframeIsEmbeddedInSubhub)
				return;
			const electricInputChanges = convertObjValsToNum(removeFalsyValueFromObj(event.data?.values));
			const { billAmount, annualUsage:energy } = electricInputChanges;
			console.log( billAmount, energy, 'electricInputChanges');
			if (!billAmount && !energy) return;
			dispatch(setQueryParamState({ ...queryParams, billAmount, annualEnergy:energy }));
		}
		window.addEventListener('message', changeBillInputs);
		return () => {
			window.removeEventListener('message', changeBillInputs);
		};
	}, [dispatch, queryParams]);

	const onLoad = React.useCallback(function callback(map: google.maps.Map) {
		const myWindow: any = window;
		const bounds = new myWindow.google.maps.LatLngBounds(
			data.state?.coordinates
		);
		map.fitBounds(bounds);

		setMap(map);
	}, [data.state?.coordinates]);

	// const latLngChange = (name:string, value:any) => {
	// 	console.log(name);
	// 	setCenter((prevCenter:any) => ({
	// 		...prevCenter,
	// 		[name]: parseFloat(value)
	// 	}));
	// };

	const onUnmount = React.useCallback(function callback() {
		setMap(undefined);
	}, []);

	const { 
		billAmount, externalId, panelKey, parentAppName,
		gutterSetback, nonGutterSetback, obstructionSetback, 
		energyDerateRate, stateCode } = queryParams;
	const design = useQuery(
		['map', data.state?.coordinates],
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		() => {
			const payload = { panelKey, gutterSetback, nonGutterSetback, externalID: externalId, billAmount: +billAmount, obstructionSetback };
			return createNewDesignReq(map?.getCenter()?.toUrlValue(), payload);
		},
		{
			enabled: false,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
			onSuccess: async (fetchedData) => {
				if (!fetchedData) return;
				window.parent.postMessage({ id: PARENT_EVENTS.SET_HOUSE, detail: { designUUID: fetchedData.designUUID } }, '*');
				const modeEnergyRate = decideDerateBasedUponMode(fetchedData.mode, energyDerateRate, stateCode ?? null);
				dispatch(setEnergyDerate({ energyDerate: modeEnergyRate }));
				dispatch(fetchRoofData({ uuid: fetchedData.designUUID as string, panelKey }));
				navigateWithQueryParams(ROUTES.SHOW_DATA);
			},
			onError: async (error) => {
				window.parent.postMessage({ id: 'SOLARQUOTEEMBED_UNSUPPORTED_ADDRESS' }, '*');
				console.log('error', error);
			},
			cacheTime: 0,
		}
	);

	if (design.isLoading) {
		return <Loader
			loaderText='Building solar panel layout'
			slider={parentAppName === SOlAR_QOUTE_APP_NAME}
		/>;
	}

	// discusss baout it for all errors
	// if (design.isError) {
	// 	return <>
	// 		<p> unsupported address</p>
	// 		<Button onClick={
	// 			() => {
	// 				{
	// 					navigate(-1);
	// 				}
	// 			}}>
	// 			Back
	// 		</Button>
	// 	</>;
	// }

	return isLoaded ? (
		<Mapwrap>
			<MapHeader>
				<div className='left'>
					{!!mainHeading && <h3 className='title'>{mainHeading}</h3>}
				</div>
				<div className='right'>
					{/* <div>
						<LatitudeInput latitudeValue={map?.getCenter()?.lat()} setError={setError} onLatChange={latLngChange}/>
					</div>
					<div>
						<LongitudeInput longitudeValue={map?.getCenter()?.lng()} setError={setError} onLngChange={latLngChange}/>
					</div> */}
					<span className='info'>{selectHouseText}</span>
					<div className='btn-container'>
						<Button
							onClick={() => design.refetch()}
							className='run-ai-btn'
						>
							<svg width="17" height="15" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.1429 2.73684C11.8817 2.73684 12.5902 2.4485 13.1127 1.93524C13.6351 1.42198 13.9286 0.725856 13.9286 0H15.2143C15.2143 0.725856 15.5078 1.42198 16.0302 1.93524C16.5526 2.4485 17.2612 2.73684 18 2.73684V4C17.2612 4 16.5526 4.28834 16.0302 4.8016C15.5078 5.31486 15.2143 6.01099 15.2143 6.73684H13.9286C13.9286 6.01099 13.6351 5.31486 13.1127 4.8016C12.5902 4.28834 11.8817 4 11.1429 4V2.73684ZM1.71429 4.21053C1.71429 3.76385 1.8949 3.33546 2.21639 3.01961C2.53788 2.70376 2.97391 2.52632 3.42857 2.52632H9.42857V0.842105H3.42857C2.51926 0.842105 1.64719 1.19699 1.00421 1.82869C0.361224 2.46039 0 3.31717 0 4.21053V12.6316C0 13.5249 0.361224 14.3817 1.00421 15.0134C1.64719 15.6451 2.51926 16 3.42857 16H13.7143C14.6236 16 15.4957 15.6451 16.1387 15.0134C16.7816 14.3817 17.1429 13.5249 17.1429 12.6316V8.42105H15.4286V12.6316C15.4286 13.0783 15.248 13.5066 14.9265 13.8225C14.605 14.1383 14.1689 14.3158 13.7143 14.3158H3.42857C2.97391 14.3158 2.53788 14.1383 2.21639 13.8225C1.8949 13.5066 1.71429 13.0783 1.71429 12.6316V4.21053Z" fill="white"/>
								<path d="M11.1429 2.73684C11.8817 2.73684 12.5902 2.4485 13.1127 1.93524C13.6351 1.42198 13.9286 0.725856 13.9286 0H15.2143C15.2143 0.725856 15.5078 1.42198 16.0302 1.93524C16.5526 2.4485 17.2612 2.73684 18 2.73684V4C17.2612 4 16.5526 4.28834 16.0302 4.8016C15.5078 5.31486 15.2143 6.01099 15.2143 6.73684H13.9286C13.9286 6.01099 13.6351 5.31486 13.1127 4.8016C12.5902 4.28834 11.8817 4 11.1429 4V2.73684ZM1.71429 4.21053C1.71429 3.76385 1.8949 3.33546 2.21639 3.01961C2.53788 2.70376 2.97391 2.52632 3.42857 2.52632H9.42857V0.842105H3.42857C2.51926 0.842105 1.64719 1.19699 1.00421 1.82869C0.361224 2.46039 0 3.31717 0 4.21053V12.6316C0 13.5249 0.361224 14.3817 1.00421 15.0134C1.64719 15.6451 2.51926 16 3.42857 16H13.7143C14.6236 16 15.4957 15.6451 16.1387 15.0134C16.7816 14.3817 17.1429 13.5249 17.1429 12.6316V8.42105H15.4286V12.6316C15.4286 13.0783 15.248 13.5066 14.9265 13.8225C14.605 14.1383 14.1689 14.3158 13.7143 14.3158H3.42857C2.97391 14.3158 2.53788 14.1383 2.21639 13.8225C1.8949 13.5066 1.71429 13.0783 1.71429 12.6316V4.21053Z" fill="var(--highlight)"/>
							</svg> {runAiBtnText}
						</Button>
					</div>
				</div>
			</MapHeader>

			<GoogleMap
				mapContainerStyle={containerStyle}
				center={center}
				zoom={19}
				options={{
					mapTypeId: google.maps.MapTypeId.HYBRID,
					disableDefaultUI: true,
					zoomControl: true,
					// zoom:20
				}}
				onLoad={onLoad}
				onUnmount={onUnmount}
			>
				{map && (
					<Marker
						position={markerPosition}
						icon={plusSymbol}
					/>
				)}
			</GoogleMap>

		</Mapwrap>
	) : (
		<></>
	);
}

export default React.memo(LocationMap);

