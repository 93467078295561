import styled, { Interpolation } from 'styled-components';

type props = {
  styles?: Interpolation<React.CSSProperties>
}

const Switch = styled.label<props>`
position: relative;
display: inline-block;
height: 34px;
${({ styles }) => styles}
width: 45px;
position: relative;
top:-1px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
	.checkbox:checked + .toggleBtn {
	background-color: #003CFF;
	border: 1.5px solid #003CFF;
	}
	.checkbox:checked + .toggleBtn:after {
		visibility: hidden;
		display: none;
	}
	.checkbox:checked + .toggleBtn:before {
			visibility: visible;
			display: block;
			color: #fff;
		}
	.checkbox:checked + .toggleBtn:before {
		content: "";
		display: flex;
		align-items: center;
		// top: 0;
		// height: 100%;
		// left: 8px;
		// text-transform: capitalize;
		// font-size: 11px;
		font-weight: 500;
	}
	.checkbox:checked + .toggleBtn span {
		left: 16px;
	}	

  .checkbox:disabled + .toggleBtn {
    background-color: #e0e0e0;
    border: 1.5px solid #e0e0e0;
    cursor: not-allowed;
  }

  .checkbox:disabled + .toggleBtn span {
    background: #bdbdbd;
    box-shadow: none;
  }
`;

const Togglebtn = styled.div`
  position: absolute;
  cursor: pointer;
  right: 0;
  bottom: 0;
  background-color: #bdbdbd;
	border: 1.5px solid #bdbdbd;
  transition: 0.4s;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
	height: 23px;
	top: 0px;
	left: 0px;
	width: 39px;

	
	&:before,&:after,span{
	position: absolute;
  top: 2px;
  width:13px;
  height: 12px;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  border-radius: 2px;
  transition: 0.3s ease all;
	}

	&:before,
	&:after {
		color: #4e4e4e;
		z-index: 1;
	}


&:after {
	content: "";
	// right: 12px;
	// top: 5px;
	// HEIGHT: 100%;
	display: flex;
	align-items: center;
	// top: 0;
	// color: #64686A;
	// opacity: .5;
	// font-size: 0.688rem;
	// line-height: 1.5;
	// font-weight: 500;
}

 span {
	width: 20px;
	height: 20px;
	border-radius: 23px;
	background: #FFFFFF;
	box-shadow: 0px 2.29167px 9.16667px rgba(0, 0, 0, 0.16);
	z-index:2;
	top: 0px;
	left: 0.65px;
  }

`;

export {
	Togglebtn, 
	Switch
};