import Button from 'components/Basic/Button';
import Konva from 'konva';
import { useRefs } from 'contexts/RefContext';
import { useEffect, useState } from 'react';
import CustomTooltip from 'components/Basic/Tooltip';

export default function UndoButton() {

	const { lastPolygonRef } = useRefs();
	const { groupRef, pointsRef, lineRef, pointLineRef } = lastPolygonRef;
	const [undoEnabled, setUndoEnabled] = useState<boolean>(!!pointsRef?.current.length);
	console.log(lastPolygonRef);

	useEffect(() => {
		const checkUndoEnabled = () => {
			setUndoEnabled(!!pointsRef?.current.length);
		};
		checkUndoEnabled();
	}, [pointsRef?.current.length]);

	function handleUndoClick() {
		if (!pointsRef?.current.length) return;

		const children = groupRef?.current?.children;
		pointLineRef?.current.points([]);

		if (children && children.length > 1) {
			const lastChild = children[children.length - 1];
			if (lastChild instanceof Konva.Image || lastChild instanceof Konva.Line) return;
			lastChild.destroy();
		}

		const points = lineRef?.current.points();
		if (points && points.length >= 2) {
			points?.splice(-2);
			lineRef?.current.points(points);
		}
		if (pointsRef.current.length > 0) {
			pointsRef.current.splice(-1);
		}
		console.log(pointsRef);
		setUndoEnabled(!!pointsRef?.current.length);
	}

	return (
		undoEnabled ?
			<>
				<Button
					className={`facetundo ${undoEnabled ? 'active' : ''}`}
					id="undo"
					style={{
						// background: `${undoEnabled ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
						// color: `${undoEnabled ? '#003CFF' : '#28373E'}`,
					}}
					onClick={handleUndoClick}
				>
					<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19 5.15385H7.92308C6.08696 5.15385 4.32605 5.88324 3.02772 7.18157C1.72939 8.4799 1 10.2408 1 12.0769C1 
						13.913 1.72939 15.6739 3.02772 16.9723C4.32605 18.2706 6.08696 19 7.92308 19H13.4615M19 
						5.15385L14.8462 1M19 5.15385L14.8462 9.30769" 
						stroke="#64686A" 
						strokeWidth="1.6" 
						strokeLinecap="round" 
						strokeLinejoin="round"/>
					</svg>
				</Button>
				{/* <CustomTooltip
					anchorSelect={'#undo'}
					content={'Undo'}
				/> */}
			</>
			:
			null
	);

}