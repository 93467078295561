import Button from 'components/Basic/Button';
import { useRefs } from 'contexts/RefContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { setMoveStage } from 'store/slices/ToolSlice';

export default function MoveButton() {
	const { moveStage } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch<AppDispatch>();
	const { konvaRef } = useRefs();

	return (
		<div>
			<Button className='zoombtnHover a' style={{
				fontSize: '0.938rem',
				borderRadius: '4px',
				fontWeight: '600',
				// background: 'transparent',
				background: `${moveStage ? '#feb517' : '#fff'}`,
				border: 'transparent',
				color: '#666666',
				padding: '0.65em 0.75em',
				boxShadow: '0px 0.5px 1.5px #0000001a',
				marginBottom:'10px'
			}} onClick={() => {	
				if(!konvaRef?.current) return;
				konvaRef.current.container().style.cursor = !moveStage? 'grab': 'default';
				dispatch(setMoveStage({ shouldMoveStage: !moveStage }));
			}}>
				<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.99111 6.32089V1.28089M8.99111 16.7067V11.6667M16.7067 
					9L11.6667 8.95378M6.33333 9L1.29333 8.95378M11.6667 3.66667C11.6667 3.66667 9.70222 
					1 9 1C8.29778 1 6.33333 3.66667 6.33333 3.66667M3.66667 11.6667C3.66667 11.6667 1 9.70222 
					1 9C1 8.29778 3.66667 6.33333 3.66667 6.33333M14.3333 6.33333C14.3333 6.33333 17 8.29778
					 17 9C17 9.70222 14.3333 11.6667 14.3333 11.6667M6.33333 14.3333C6.33333 14.3333 8.29778 
					 17 9 17C9.70222 17 11.6667 14.3333 11.6667 14.3333" 
					stroke={`${moveStage ? '#28373e' : '#666666'}`} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
				</svg>
			</Button>
		</div>
	);

}