import styled from 'styled-components';

export const ThreeDButtonWrap = styled.div`
// margin-left: 10px;
`;
export const EditAnd3DdButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap:1rem;
	align-items:center;
`;

export const StripProduction = styled.span`
background: linear-gradient(90deg, #DE5B12 0%, #FFD159 89.5%);
  max-width: 100%;
  height: 28px;
  height: auto !important;
  border-radius: 50px;
  border: 2px solid #fff;
  position: relative;
  font-family: 'Sf-Bold', sans-serif;
  display: flex;
  justify-content: space-between;
  font-size: 0.62rem;
  align-items: center;
  z-index: 1;
  padding: 0.5em 0.8em;
  width: 100%;

  &:before {
    content: 'Lowest Production';
    font-size: 1em;
    color: #fff;
  }

  &:after {
    content: 'Max Production';
    position: absolute;
    right: 1em;
    color: #51250d;
  }
`;

export const MainDiv = styled.div`
  // top: 1rem;
  // left: 1rem;
  z-index: 3;
  // width: 100%;
  display: flex;
  gap: 5rem;
  align-items:center;
  height: auto !important;
  // position: absolute !important;
  justify-content: space-between;
  left: 0px;
  padding: 0 8px 0 0px;
  @media (max-width: 640px){
    flex-direction:column;
    align-items: flex-start;
    gap:1rem
  }
	pointer-events: none;
`;

export const ToolBarWrapper = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
pointer-events: all;
gap:.75rem;

	@media (max-width: 1325px){
	gap: 0.85rem;
	}
	@media (max-width: 512px){
			flex-wrap: wrap;
		justify-content: flex-start;
	}
		button{
		}
`;

export const EditToolOptionsDiv = styled.div`
display: flex;
align-items: center;
pointer-events: all;
    background: linear-gradient(0deg, rgb(255, 255, 255), rgb(255, 255, 255)), rgb(254, 181, 23);
    color: rgb(34, 51, 73);
    font-family: Inter;
    border: 1px solid rgb(228, 228, 228);
    box-shadow: none;
    border-radius: 91.4939px;
    height: 40px;
		overflow:hidden
`;
export const PanelToolWrap = styled.div`
	display: flex;
	align-items:center;
	gap: 6px;
  position: relative;
	padding-left: .75rem;
`;
export const FacetPanelWrap = styled.div`
	display: flex;
	align-items:center;
	gap: 6px;
	position: relative;

`;
export const CalculatebtnWrap = styled.div`
	padding-left: 0px;
	display: flex;
	gap: .75rem;
`;
export const ToolbarLabel = styled.div`
	font-family: Inter;
	font-weight: 600;
	color: #4A525A;
	padding-right: 0px;
	line-height: normal;
	font-size: 0.875rem;
`;

export const PanelwithOreintation = styled.div`
	position: relative;
`;
export const DrawFacetWrap = styled.div`
	position: relative;
`;
export const ModalBox = styled.div`
		display: flex;
		align-items: center;
		pointer-events: all;
		border: 1px solid rgb(228,228,228);
		border-radius: 91.4939px;
		overflow: hidden;
		position: absolute;
		top: 40px;
		left: 50px;
		padding: .275rem 0.813rem;
		background: #fff;
`;
