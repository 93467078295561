import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type DropdownState = {
	open: boolean;
	position?: Vector2d |null,
	shadingModal:{
		type: 'undo'|'remove'
		open: boolean
	},
	recalculateLayoutModalOpen: boolean
};

const initialState: { data: DropdownState } = {
	data: {
		open: false,
		shadingModal:{
			type: 'remove',
			open: false
		},
		recalculateLayoutModalOpen: false
	}
};

export const EditFacetModalSlice = createSlice({
	name: 'editFacetModal',
	initialState,
	reducers: {
		setDropdownState(state, action: PayloadAction<Partial<DropdownState>>) {
			state.data.open= !!action.payload.open;
			state.data.position= action.payload.position || null;
		},
		toggleShadingModalState(state, action: PayloadAction<{ value: boolean, type: 'remove' | 'undo'}>) {
			state.data.shadingModal = {
				open: action.payload.value,
				type: action.payload.type
			};
		},
		toggleUndoShadingModalState(state, action: PayloadAction<{ value: boolean}>) {
			state.data.shadingModal = {
				open: action.payload.value,
				type: 'undo'
			};
		},
		toggleRecalculateLayoutModalState(state, action: PayloadAction<{ value: boolean}>) {
			state.data.recalculateLayoutModalOpen = action.payload.value;
		},
	}
});

export const { setDropdownState, toggleShadingModalState, toggleUndoShadingModalState, 
	toggleRecalculateLayoutModalState } = EditFacetModalSlice.actions;

export default EditFacetModalSlice.reducer;