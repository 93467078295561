import { useLayoutEffect, useRef, useState } from 'react';
import { SliderInput, SliderWrap, PitchWrap } from 'components/Slider/styles';

const getSliderlinearGrad = (val: number, active:boolean) => `linear-gradient(to right, ${active? 'var(--secondary)': '#8497AE'} 0%, ${active? 'var(--secondary)': '#8497AE'} ${val}%, #BCBDC6 ${val}%, #BCBDC6 100%)`;
export default function PitchSlider(props: { pitch: number, onChange?: (value: string) => void , active: boolean}) {
	const sliderRef = useRef<HTMLInputElement | null>(null);
	const [pitchval, setPitchVal]= useState<number>(props.pitch);

	useLayoutEffect(() => {
		if (!sliderRef.current) return;
		sliderRef.current.style.background = getSliderlinearGrad((props.pitch / 90) * 100, props.active);
	}, [props.active, props.pitch]);

	return (
		<SliderWrap>
			<PitchWrap className="pitch-label">
				<span>Pitch:</span>
				<span className="pitch-degree">{Math.round(pitchval * 100) / 100}<sup className="degree">0</sup></span>
			</PitchWrap>
			<SliderInput
				type='range'
				min={0}
				max={90}
				defaultValue={props.pitch}
				step={1}
				ref={sliderRef}
				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
					const value = Math.ceil(+e.target.value);
					e.target.value = value.toString();
					setPitchVal(value);
					const rangeVl = (value / 90) * 100;
					if (sliderRef.current)
						sliderRef.current.style.background = getSliderlinearGrad(rangeVl, props.active);
					props.onChange?.(e.target.value);
				}}
			/>
		</SliderWrap>
	);
}
