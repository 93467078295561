import Button from 'components/Basic/Button';
import { useRefs } from 'contexts/RefContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { batchUpdateToolState } from 'store/slices/ToolSlice';
import { DEFAULT_KONVA_SCALE, DEFAULT_KONVA_STAGE_POSITION, PARENT_EVENTS } from '../../../../constants';
import { useEffect } from 'react';

export default function FullViewBtn() {

	const { fullScreenEnabled } = useSelector((state: RootState) => state.toolNewPostions.data);
	const dispatch = useDispatch();

	useEffect(() => {
		function handleSaveDesignEvent(event: MessageEvent) {
			if (event.data === 'DISABLE_FULL_SCREEN_MODE') {
				dispatch(batchUpdateToolState({
					fullScreenEnabled: false, zoomLevel: DEFAULT_KONVA_SCALE, konvaStagePosition: DEFAULT_KONVA_STAGE_POSITION
				}));
			}
		}
		window.addEventListener('message', handleSaveDesignEvent);
		return () => {
			window.removeEventListener('message', handleSaveDesignEvent);
		};
	});

	function toggleFullScreen(enabled = fullScreenEnabled) {
		dispatch(batchUpdateToolState({ fullScreenEnabled: !enabled, zoomLevel: DEFAULT_KONVA_SCALE }));
		window.parent.postMessage(
			{ id: 'FULL_VIEW_MODE', detail: { fullScreenEnabled: !enabled } },
			'*'
		);
	}
	return (
		<Button
			onClick={() => toggleFullScreen()}
			className={'fullscreenviewVisible btnHover ' + `${fullScreenEnabled ? ' active' : ''}`}
		>
			
			{!fullScreenEnabled && <svg width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 6V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H6M17 12V15C17 15.5304 16.7893 16.0391 16.4142 16.4142C16.0391 16.7893 15.5304 17 15 17H12M12 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V6M6 17H3C2.46957 17 1.96086 16.7893 1.58579 16.4142C1.21071 16.0391 1 15.5304 1 15V12" stroke={`${fullScreenEnabled ? '#fff' : '#64686A'}`} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>}
			{fullScreenEnabled && <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M1 11.3125L3.8125 11.3125C4.30978 11.3125 4.78669 11.51 5.13832 11.8617C5.48996 12.2133 5.6875 12.6902 5.6875 13.1875L5.6875 16" stroke="#64686A" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M11.3125 16L11.3125 13.1875C11.3125 12.6902 11.51 12.2133 11.8617 11.8617C12.2133 11.51 12.6902 11.3125 13.1875 11.3125L16 11.3125" stroke="#64686A" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M16 5.6875L13.1875 5.6875C12.6902 5.6875 12.2133 5.48996 11.8617 5.13832C11.51 4.78669 11.3125 4.30978 11.3125 3.8125L11.3125 1" stroke="#64686A" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M5.6875 1L5.6875 3.8125C5.6875 4.30978 5.48996 4.78669 5.13833 5.13833C4.78669 5.48996 4.30978 5.6875 3.8125 5.6875L1 5.6875" stroke="#64686A" strokeWidth="1.875" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
			}
		</Button>
	);

}