import { useDispatch } from 'react-redux';
import PitchSlider from './PitchSlider';
import { DivPanelCount, AzimuthHeading, DivPitchWrap, DivFacetDetail } from './style';
import { AppDispatch } from 'store';
import { toggleManualToolDoneButtonState, toggleManualToolResetButtonState } from 'store/slices/ManualToolModal';
import { KONVA_FACET_GROUP } from '../../constants';

function AzimuthDiv(props: { azimuthDegrees: number }) {
	const roundedValue = Math.round(props.azimuthDegrees);
	return (
		<AzimuthHeading>
			Azimuth:
			<span>{roundedValue}</span>
		</AzimuthHeading>
	);
}

function PitchDiv(props: { pitchDegrees: number }) {
	const roundedValue = Math.round(props.pitchDegrees);
	return (
		<DivPitchWrap>
			Pitch:
			<span>{roundedValue}</span>
		</DivPitchWrap>
	);
}
function PanelsCountDiv(props: { panelsCount: number }) {
	return (

		<DivPanelCount>
			Panels Count:
			<span>{props.panelsCount} </span>
		</DivPanelCount >
	);
}

function SystemProductionDiv(props: { systemProduction: number }) {
	const roundedValue = Math.round(+props.systemProduction.toFixed(2));
	return (
		<DivPanelCount>
			System Production:
			<span>{roundedValue} kWh</span>
		</DivPanelCount>
	);
}

function SystemSize(props: { systemSize: number }) {
	const roundedValue = +props.systemSize.toFixed(2);
	return (
		<DivPanelCount>
			System Size:
			<span>{roundedValue} kW</span>
		</DivPanelCount>
	);
}

type Props = {
	groupSuffix: string;
	konvaPolygonId: string;
	active?: boolean;
	onChange?: (id: string, groupSuffix: string) => void;
	azimuthDegrees: number;
	pitchDegrees: number;
	systemSize: number;
	systemProduction: number;
	panelsCount: number;
	previousPitch: number;
	isGroundMount? : boolean,
	onPitchValChange?: (id: string, groupSuffix: string, value: any) => void;
};
export default function DefaultModeValues(props: Props) {
	const dispatch = useDispatch<AppDispatch>();
	function handleClick() {
		props.onChange && props.onChange(props.konvaPolygonId, props.groupSuffix);
	}

	return (
		<>
			{
				<DivFacetDetail className={` ${props.active ? ' active' : ''}`} onClick={handleClick}>
					<AzimuthDiv azimuthDegrees={props.azimuthDegrees} />
					{(props?.isGroundMount) ? 
						<div className='facet-slider-wrap facet-slider-slope-info'>
							{
								<PitchSlider 
									pitch={props.pitchDegrees}
									active= {!!props.active }
									onChange={(v) => {
										props.onPitchValChange && props.onPitchValChange(props.konvaPolygonId, props.groupSuffix, +v);
										props.onChange && props.onChange(props.konvaPolygonId, props.groupSuffix);
										dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: true }));
										if(props.groupSuffix.slice(1) != KONVA_FACET_GROUP.IN_PROGRESS_GROUP_NAME){
											dispatch(toggleManualToolResetButtonState({resetEnabled: true}));
										}
									}}
								/>
							}
						</div> : <PitchDiv pitchDegrees={props.pitchDegrees} />}
					<SystemProductionDiv systemProduction={props.systemProduction} />
					<SystemSize systemSize={props.systemSize} />
					<PanelsCountDiv panelsCount={props.panelsCount} />
				</DivFacetDetail>
			}
		</>
	);

}