import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { redoPanelState, undoPanelState } from 'store/slices/PanelSlice';
import { RootState } from 'store';

export default function RedoButton() {
	const dispatch = useDispatch();
	const { newlyAddedPanels, deletedPanels, enableAddPanel, deleteIndividualPanelMode, currentIndex, panelHistory } = useSelector((state: RootState) => state.panelSlice.data);
	const show = (deleteIndividualPanelMode || enableAddPanel) && !!(newlyAddedPanels?.length || deletedPanels?.length) || panelHistory.length > 0;
	const shouldDisable = (currentIndex <= 0);
	
	function handleClick() {
		dispatch(undoPanelState());
	}

	if (show) {
		return (
			<>
				<Button
					className='active'
					id="undo"
					style={{
						background:`${shouldDisable ? '#fff' : 'rgb(from #003CFF r g b / 10%)'}`,
						border: '0px',
						borderRight: '1px solid #E4E4E4',
						borderLeft: '0px',
						color: '#003CFF',
						fontSize: '14px',
						padding: '0px 8px',
						height: '40px',
						minWidth: '38px',
						borderRadius: '0px',
						// transform: 'rotate(180deg)'
						transform: 'scale(-1, 1)',
						position: 'relative',
						right: '-1px'
					}}
					disabled={shouldDisable}
					onClick={handleClick}
					tooltipContent='Undo'>
					<svg width="16" height="18" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M19.6296 17.3987C17.8772 20.2562 14.7634 22.1569 11.2142 22.1569C5.73672 22.1569 
					1.29639 17.63 1.29639 12.0457C1.29639 6.46149 5.73672 1.93457 11.2142 1.93457C15.2358 
					1.93457 18.6983 4.3749 20.2548 7.88231M20.2548 7.88231V2.52934M20.2548 7.88231H15.2979"
						stroke={`${shouldDisable ? '#64686A' : '#003CFF'}`}
						strokeWidth="1.90964"
						strokeLinecap="round"
						strokeLinejoin="round" />
					</svg>

				</Button>
			</>
		);
	}

	return null;

}