import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleRecalculateLayoutModalModalSliceState } from 'store/slices/RecalculateLayoutSlice';
import { useQuery } from 'react-query';
import { getAbsolutePointsOfTranslatedPanels } from 'components/ManualToolModal/util';
import { translatePanels, createNewFacets } from 'components/ManualToolModal/api';
import { toast } from 'react-toastify';
import {
	batchUpdateToolState,
	setDeletedRoofIndexes,
	setEditState,
	setRoofIndexes,
	setUserModificationState,
	updateTransition
} from 'store/slices/ToolSlice';
import { useRefs } from 'contexts/RefContext';
import { deleteAllFacetsFromPanelDrawer, disableDraggingInKonvaGroups, resetTransformations } from 'components/tool/util';
import Konva from 'konva';
import { KONVA_FACET_GROUP, DESIGN_MODES, DEFAULT_SHADING_VAL } from '../../../../constants';
import { transformPointsToOriginalScale } from 'components/DisplayEnergy/util';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { toggleManualToolDoneButtonState, toggleManualToolModalState, toggleManualToolResetButtonState } from 'store/slices/ManualToolModal';
import { deleteObstructions } from '../ButtonDiv/Facet/DeleteObstructionBtn/api';
import { addNewPanels, movablePanels, removeIndividualPanels } from '../ButtonDiv/api';
import { setAddPanel, setDeleteIndividualPanelsMode, setPanelMove } from 'store/slices/PanelSlice';

export default function RecalculateLayoutModal() {
	const { open, warningMessage } = useSelector(
		(state: RootState) => state.recalculateLayoutModal.data
	);
	const { newlyCreatedFacets, deletedRoofIndexes, roofIndexes, groundMountEnabled, deletedObstructionIDs } = 
	useSelector((state: RootState) => state.toolNewPostions.data);
	const { newlyAddedPanels, deletedPanels, currentMovablePanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { uuid, panel, mode, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { konvaRef } = useRefs();
	const scale= useKonvaScale();
	const dispatch = useDispatch<AppDispatch>();
	const notDefaultMode = mode !== DESIGN_MODES.DEFAULT;
	//TO_DO: refactor the condition should be same for all operation incluing panel mode
	function getNewFacets() {
		if((!Object.keys(newlyCreatedFacets).length && !deletedRoofIndexes.length)){
			return;
		}

		const transformedFacets = transformFacets();
		return {
			transformedFacets,
			deletedRoofIndexes
		};
	}

	function transformFacets() {
		if (mode === DESIGN_MODES.NEARMAP_NREL_MANUAL) {
			return Object.values(newlyCreatedFacets).map(facet => ({
				hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale, coord))),
				azimuthDegrees: facet.azimuthDegrees,
				pitchDegrees: facet.pitchDegrees ? +facet.pitchDegrees : groundMountEnabled ? 18 : 20,
				shading: facet.shading ? +facet.shading : DEFAULT_SHADING_VAL,
				isGroundMount: facet?.isGroundMount,
			}));
		}
		
		return Object.values(newlyCreatedFacets).map(facet => ({
			hullCoords: facet.hullCoords.map(coord => (transformPointsToOriginalScale(scale,coord))),
			isGroundMount: facet?.isGroundMount,
			azimuthDegrees: facet.azimuthDegrees,
		}));
	}

	function deleteFacet() {
		if (!konvaRef?.current) return;
		deleteAllFacetsFromPanelDrawer(konvaRef.current);
	}

	async function resetAllModifiedStates(){
		dispatch(setPanelMove({ shouldEnablePanelMove: false }));
		dispatch(setAddPanel({ shouldEnable: false }));
		dispatch(setDeleteIndividualPanelsMode({enabled: false}));
		dispatch(setRoofIndexes({ roofIndexes: [] }));
		dispatch(setDeletedRoofIndexes({deletedRoofIndexes:[]}));
		dispatch(updateTransition({ currentTransitionState: false }));

		if (konvaRef?.current) {
			(konvaRef.current?.findOne('Transformer') as Konva.Transformer).setNodes([]);
			disableDraggingInKonvaGroups(konvaRef?.current.find('.' + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR));
			resetTransformations(konvaRef?.current.find('.' + KONVA_FACET_GROUP.GROUP_NAME_SEPARATOR));
		}

	}

	const createNewFacetsQuery = useQuery(
		'createNewFacets',
		async () => {
			const newFacets = getNewFacets();
			if (!newFacets) return;
			await createNewFacets({
				newPanelFacets: newFacets.transformedFacets,
				deletedRoofSegs: deletedRoofIndexes,
			}, panel.key, uuid);
			await dispatch(changePanel({ uuid, panelKey: panel.key }));
			deleteFacet();
		},
		{
			onSuccess: async () => {
				dispatch(setDeletedRoofIndexes({deletedRoofIndexes:[]}));
				dispatch(setUserModificationState({hasMadeChanges: true}));
			},
			onError: (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE') return;
			},
			// retry: (failureCount, error: Error) => {
			// 	return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE';
			// },
			retry: 0,
			enabled: false,
		}
	);

	const addNewPanelsQuery = useQuery(
		'addPanels',
		async () => {
			if(!konvaRef?.current || !newlyAddedPanels.length) throw new Error('E_NO_PNEL_TO_ADD');
			await addNewPanels(newlyAddedPanels, panel.key, uuid);
			await dispatch(changePanel({ uuid, panelKey: panel.key }));
		},
		{
			onSuccess: async () => await resetAllModifiedStates(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const removePanelsQuery = useQuery(
		'removePanels',
		async () => {
			await removeIndividualPanels(deletedPanels, panel.key, uuid);
			await dispatch(changePanel({ uuid, panelKey: panel.key }));
		},
		{
			onSuccess: async () => await resetAllModifiedStates(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const movablePanelsQuery = useQuery(
		'movablePanels',
		async () => {
			await movablePanels(currentMovablePanels, panel.key, uuid);
			await dispatch(changePanel({ uuid, panelKey: panel.key }));
		},
		{
			onSuccess: async () => await resetAllModifiedStates(),
			onError: (error: Error) => {
				console.log(error);
			},
			retry: 0,
			enabled: false,
		}
	);

	const deleteObstructionsQuery = useQuery(
		'deleteObstructions',
		async () => {
			if (!deletedObstructionIDs?.length) return;
			await deleteObstructions( uuid, deletedObstructionIDs);
			await dispatch(changePanel({ uuid, panelKey: panel.key }));
		},
		{
			onSuccess: async () => {
				dispatch(batchUpdateToolState({
					deletedObstructionIDs: [],
					deleteObstructionsMode: false,
					userHasModifiedRoofSegs: true,
					currentActiveFacetEditMode: undefined,
					facetHistory: { currentIndex: 0, history: [] }
				}));
			},
			retry: 0,
			enabled: false,
		}
	);
	const translatePanelPostionsQuery = useQuery(
		'translate-panels',
		async () => {
			if(!konvaRef?.current) return ;
			if(deletedRoofIndexes.length) {
				await createNewFacets({ newPanelFacets: [], deletedRoofSegs: deletedRoofIndexes }, panel.key, uuid);
			}
			const newTranslatedPostions = getAbsolutePointsOfTranslatedPanels(konvaRef.current, roofIndexes, allRoofSegs, scale);
			if (!newTranslatedPostions.length) throw new Error('E_NO_TRANSLATIONS_TO_SAVE');
			await translatePanels(newTranslatedPostions, panel.key, uuid);
			await dispatch(changePanel({ uuid, panelKey: panel.key }));
		},
		{
			onSuccess: async () => {
				await resetAllModifiedStates();
			},
			onError: async (error: Error) => {
				if (error.message === 'E_NO_TRANSLATIONS_TO_SAVE' || error.message === 'E_WHILE_GETTING_TRANSLATION_FOR_SEGMENT') {
					dispatch(setRoofIndexes({ roofIndexes: [] }));
					if(deletedRoofIndexes.length){
						await dispatch(changePanel({ uuid, panelKey: panel.key }));
						dispatch(setDeletedRoofIndexes({deletedRoofIndexes:[]}));
						
					}
				
					return;
				}
				toast.error('something went wrong, please try again');
			},
			retry: (failureCount, error: Error) => {
				return error.message !== 'E_NO_TRANSLATIONS_TO_SAVE' && failureCount < 2;
			},
			enabled: false,
		}
	);
	function handleClick(){
		const showManualModal = notDefaultMode && Object.keys(newlyCreatedFacets).length;
		if (newlyAddedPanels.length) {
			addNewPanelsQuery.refetch();
		} else if (deletedPanels.length) {
			removePanelsQuery.refetch();
		} else if (currentMovablePanels.length) {
			movablePanelsQuery.refetch();
		} else if(deletedObstructionIDs.length){
			deleteObstructionsQuery.refetch();
		}else if(roofIndexes.length) {
			translatePanelPostionsQuery.refetch();
		}
		else {
			if(showManualModal){
				dispatch(toggleManualToolModalState({value: true}));
			}
			else{
				createNewFacetsQuery.refetch();
			}
		}
		dispatch(toggleManualToolModalState({ value: false }));
		dispatch(toggleManualToolDoneButtonState({ inputFieldsEdited: false }));
		dispatch(toggleManualToolResetButtonState({ resetEnabled: false }));
	}

	function hanldeConfirmation() {
		closeModal();
		handleClick();
	}

	const closeModal = () => {
		dispatch(toggleRecalculateLayoutModalModalSliceState({
			open: false,
			warningMessage: '',
		}));
	};

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open) closeModal();
			}}
		>
			<ModalPopup>
				<ModalcontentInfo>
					{warningMessage}
				</ModalcontentInfo>
				<ModalbuttonWrap>
					<Modalbutton onClick={() => hanldeConfirmation()}>
											Yes
					</Modalbutton>
					<ModalbuttonNo onClick={() => closeModal()}>
											No
					</ModalbuttonNo>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}
