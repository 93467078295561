import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ModalPopup, ModalcontentInfo, ModalbuttonWrap, Modalbutton, ModalbuttonNo, ModaliconInfo } from './style';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { toggleOrientationModalState } from 'store/slices/ChangeOrientationModalSlice';
import { useQuery } from 'react-query';
import { changeFacetOrientation } from './api';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { toast } from 'react-toastify';
import { toggleWarningModalState } from 'store/slices/WarningSlice';

const popupMsg = 'Are you sure you want to change the facet\'s orientation?';
export default function ChangeOrientation() {
	const { open } = useSelector((state: RootState) => state.changeOrientationModal.data);
	const { selectedRoofIndex, deletedRoofIndexes } = useSelector((state: RootState) => state.toolNewPostions.data);
	const { allRoofSegs, uuid, panel } = useSelector((state: RootState) => state.roofData.data);

	const dispatch = useDispatch<AppDispatch>();

	const changeFacetOrientationQuery = useQuery(
		'changeFacetOrientation',
		async () => {
			if (!selectedRoofIndex) return;
			const selectedRoof = allRoofSegs.find(r => r.id === selectedRoofIndex);
			const panelsCount = await changeFacetOrientation(
				selectedRoofIndex, uuid, panel.key, selectedRoof?.orientation as string, !!selectedRoof?.userMapped
			);
			return { panelsCount, selectedRoofIndex };
		},
		{
			onSuccess: async (data: { panelsCount: number, selectedRoofIndex: string }) => {
				if (data?.panelsCount == 0) {
					dispatch(toggleWarningModalState({
						open: true,
						warningMessage: 'We are not able to generate any panel in this orientation.',
						selectedRoofIdx: data?.selectedRoofIndex,
						noOfPanels: data?.panelsCount
					}));
				}
				else if (data?.panelsCount < 3) {
					dispatch(toggleWarningModalState({
						open: true,
						warningMessage: 'We are not able to generate more than 2 panels in this orientation, do you still want to continue?',
						selectedRoofIdx: data?.selectedRoofIndex,
						noOfPanels: data?.panelsCount
					}));
				}
				await dispatch(changePanel({ uuid, panelKey: panel.key }));
				dispatch(toggleOrientationModalState({ open: false, isLoading: false }));
			},
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			onError: (error: Error) => {
				toast.error('something went wrong, please try again');
			},
			retry: 0,
			enabled: false,
		}
	);

	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
			onClose={() => {
				if (open)
					dispatch(toggleOrientationModalState({ open: false, isLoading: false }));
			}}>
			<ModalPopup>
				<ModaliconInfo>
					<svg width="50" height="50" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M4.27955 47.2561C-0.717624 28.459 10.4876 9.18148 29.2654 4.18431C42.604 0.647853 56.2117 5.26063 64.7453 14.9667" stroke="#FEB517" strokeWidth="5.62873" strokeMiterlimit="10" strokeLinecap="round" />
						<path d="M12.0035 61.6506C11.8885 61.5356 11.7927 61.4015 11.6777 61.2865" stroke="#FEB517" strokeWidth="5.62873" strokeMiterlimit="10" strokeLinecap="round" />
						<path d="M72.3237 29.1875C77.3209 47.9846 66.1157 67.2621 47.3379 72.2593C34.0762 75.7765 20.5646 71.2406 12.0117 61.6499" stroke="#FEB517" strokeWidth="5.62873" strokeMiterlimit="10" strokeLinecap="round" />
						<path d="M65.0316 10.4324C65.4251 9.92738 66.2176 10.0377 66.4583 10.6309L69.0495 17.0192C69.2901 17.6124 68.7983 18.2437 68.1642 18.1555L61.3362 17.2054C60.7021 17.1172 60.4013 16.3756 60.7948 15.8706L65.0316 10.4324Z" fill="#FEB517" />
						<path d="M6.41032 57.3931C6.25603 56.7718 6.83218 56.2164 7.4474 56.3935L14.0724 58.2998C14.6876 58.4769 14.8805 59.2535 14.4196 59.6978L9.45612 64.482C8.9952 64.9263 8.22618 64.705 8.07188 64.0837L6.41032 57.3931Z" fill="#FEB517" />
						<path d="M25.2793 42.0253L33.1503 48.7884C33.6812 49.227 34.466 49.1808 34.9276 48.673L54.5937 27.1143" stroke="#FEB517" strokeWidth="5.62873" strokeMiterlimit="10" strokeLinecap="round" />
					</svg>
				</ModaliconInfo>
				<ModalcontentInfo>
					{popupMsg}
				</ModalcontentInfo>
				<ModalbuttonWrap>
					<ModalbuttonNo onClick={() => {
						dispatch(toggleOrientationModalState({ open: false, isLoading: false }));
					}}>
						No
					</ModalbuttonNo>
					<Modalbutton onClick={() => {
						if (!selectedRoofIndex) return;
						if (deletedRoofIndexes.includes(selectedRoofIndex)) return;
						changeFacetOrientationQuery.refetch();
						dispatch(toggleOrientationModalState({ open: false, isLoading: true }));
					}}>
						Yes
					</Modalbutton>
				</ModalbuttonWrap>
			</ModalPopup>
		</Popup>
	);
}