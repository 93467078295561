import { useSelector } from 'react-redux';
import { RootState } from 'store';
import DoneBtn from './ButtonDiv/CreateBtn';
import {
	EditToolOptionsDiv, MainDiv, ToolBarWrapper, PanelToolWrap,
	FacetPanelWrap, CalculatebtnWrap, ToolbarLabel, PanelwithOreintation,ModalBox
} from './style';
import DrawBtn from './ButtonDiv/Facet/DrawBtn';
import EditToolOptions from './ButtonDiv/Facet/EditToolOptions';
import AddPanelButton from 'components/tool/Editor/ButtonDiv/Panel/AddPanelButton';
import MovePanelButton from 'components/tool/Editor/ButtonDiv/Panel/MovePanelButton';
import DeletePanelBtn from './ButtonDiv/Panel/DeletePanelBtn';
import UndoFacetButton from './ButtonDiv/Facet/UndoButton';
import UndoPanelButton from './ButtonDiv/Panel/UndoBtn';
import RedoPanelButton from './ButtonDiv/Panel/RedoBtn';
import CancelBtn from './ButtonDiv/Panel/CancelBtn';
import GroundMountToggle from './GroundMountToggle';
import SwitchOrientation from 'components/SwitchOrientation';
import CustomTooltip from 'components/Basic/Tooltip';

export default function EditorToolBar() {
	const { showIrradiance } = useSelector((state: RootState) => state.Irradiance.data);
	const { editModeEnabled, deletedRoofIndexes, drawModeEnabled, roofIndexes, deleteObstructionsMode, newlyCreatedFacets } = 
	useSelector((state: RootState) => state.toolNewPostions.data);
	const { activePanelMode, enableAddPanel } = 	useSelector((state: RootState) => state.panelSlice.data);
	const hasUserMadeModificaiton = !!deletedRoofIndexes?.length || !!Object.keys(newlyCreatedFacets)?.length || !!roofIndexes?.length;
	const showDoneBtnIfUserHasModified = editModeEnabled && (hasUserMadeModificaiton || activePanelMode || drawModeEnabled || deleteObstructionsMode );
	const showCanelBtnInIndividualPanelFnMOde= editModeEnabled && (drawModeEnabled || deleteObstructionsMode || activePanelMode);
	
	if(showIrradiance){
		return null;
	}

	return (
		<MainDiv>
			<ToolBarWrapper className='toolbar-wrap'>
				<FacetPanelWrap className='toolbar-btn-wrap a'>
					<ToolbarLabel>Facets</ToolbarLabel>
					<EditToolOptionsDiv>
						<DrawBtn />
						<EditToolOptions />
						{/* {drawModeEnabled && <UndoFacetButton />} */}
					</EditToolOptionsDiv>
					{drawModeEnabled && <ModalBox>
						<GroundMountToggle />
						<UndoFacetButton />
					</ModalBox> }
				</FacetPanelWrap>
			
				<PanelToolWrap className='toolbar-btn-wrap'>
					<ToolbarLabel>Panels</ToolbarLabel>
					<EditToolOptionsDiv>
						<PanelwithOreintation>
							<AddPanelButton />
							<CustomTooltip
								anchorSelect={'#add-panel'}
								content={'Add Panel'}
							/>
						</PanelwithOreintation>
						<MovePanelButton />
						<DeletePanelBtn />
						<UndoPanelButton />
						<RedoPanelButton />

					</EditToolOptionsDiv>
					{enableAddPanel && <SwitchOrientation />}
				</PanelToolWrap>
				<CalculatebtnWrap>
					{ showDoneBtnIfUserHasModified && <DoneBtn /> }
					{ showCanelBtnInIndividualPanelFnMOde && <CancelBtn /> }
				</CalculatebtnWrap>

			</ToolBarWrapper>
		</MainDiv>
	);
}