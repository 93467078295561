import { ModalPopup, Modalbutton, ModalbuttonNo, ModalbuttonWrap, ModalcontentInfo } from 'components/OffsetModal/styles';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popup from 'reactjs-popup';
import { AppDispatch, RootState } from 'store';
import { fetchIrradianceImages } from 'store/slices/IrradianceSlice';
import { changePanel } from 'store/slices/RoofDataSlice/roofDataSlice';
import { IMAGERY_PROVIDER, API_ERROR_CODES, DESIGN_MODES } from '../../constants';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';
import { useQuery } from 'react-query';
import { switchDesignImagery } from './api';
import { toast } from 'react-toastify';
import { errorResponseHandler } from 'store/api/AxiosError';
import { AxiosError } from 'axios';
import { DivMapping, SVGMap, SelectList, SVGDown } from './styles';

export default function SwitchProvider() {
	const { uuid, panel, roofDataChanging, imagerySource, mode} = useSelector((state: RootState) => ({
		...state.roofData.data,
		roofDataChanging: state.roofData.configuringNewPanel || state.roofData.loading || state.roofData.switchingImageryProvider
	}));
	const [selectorImageProvidor, setSelectorImageProvidor] = useState(imagerySource);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch<AppDispatch>();
	const allQueriesRunningInBackGround = useQueriesRunningInBackGround();
	const energyQueriesInProgrss = useQueriesRunningInBackGround(['save', `monthlyDetails-${uuid}`, `hourlyEnergy-${uuid}`]);
	const disableSelect = roofDataChanging || energyQueriesInProgrss || allQueriesRunningInBackGround || mode !== DESIGN_MODES.DEFAULT;

	const switchDesignImageryQuery = useQuery(
		`switch-imagery-${imagerySource}`,
		async () => {
			await switchDesignImagery({ uuid, panelKey: panel.key, source: selectorImageProvidor });
		},
		{
			onError: (err: Error) => {
				const { message } = errorResponseHandler(err as AxiosError);
				toast.error(message);
			},
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onSuccess: async () => {
				await dispatch(changePanel({ uuid, panelKey: panel.key, switchingSource: true }));
				if (mode !== DESIGN_MODES.DEFAULT) return;
				dispatch(fetchIrradianceImages({ uuid }));
			},
			retry: (failureCount, error: Error) => {
				const { error: parsedErrorMsg } = errorResponseHandler(error as AxiosError);
				console.log('error', error, parsedErrorMsg);
				return parsedErrorMsg !== API_ERROR_CODES.INVALID_IMAGERY_CHECKOUT && failureCount < 2;
			},
			enabled: false,
		}
	);

	const handleChange = (e:React.ChangeEvent<HTMLSelectElement>) => {
		const currentProvider = e.target.value as ImagerySource;
		setOpen(true);
		setSelectorImageProvidor(currentProvider);
	};
	const message = 'This will reset all your progress on the map. Are you sure you want to continue?';
	const handleConfirmation = async () => {
		switchDesignImageryQuery.refetch();
		setOpen(false);
	};

	return (
		<>
			<DivMapping>
				{/* <svg className='imageIcon'
					style={{position: 'absolute',paddingLeft:'5px',color:'#a1a1a1',marginLeft: '7px',
						left: '0px'}} width="27" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18.8372 12.186V1.60465C18.8372 1.27071 18.5665 1 18.2326 
					1H1.60465C1.27071 1 1 1.27071 1 1.60465V13.3953C1 13.7293 1.27071 14 1.60465 
					14H6.44186M18.8372 12.186V13.3953C18.8372 13.7293 18.5665 14 18.2326 14H6.44186M18.8372 
					12.186L15.2093 8.55814C14.3023 7.65116 13.2442 7.19767 11.8837 8.55814L6.44186 14" stroke="#4A525A" strokeWidth="1.5"/>
					<circle cx="5.83739" cy="5.83739" r="1.81395" stroke="#4A525A" strokeWidth="1.5"/>
				</svg> */}
				<SVGMap className='imageIcon'
					width="24" height="18" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M18.8372 12.186V1.60465C18.8372 1.27071 18.5665 1 18.2326 1H1.60465C1.27071 1 1 1.27071 1 
					1.60465V13.3953C1 13.7293 1.27071 14 1.60465 14H6.44186M18.8372 12.186V13.3953C18.8372 13.7293 18.5665 
					14 18.2326 14H6.44186M18.8372 12.186L15.2093 8.55814C14.3023 7.65116 13.2442 7.19767 11.8837 8.55814L6.44186 14" 
					stroke="#64686A" strokeWidth="1.6"/>
					<circle cx="5.83739" cy="5.83788" r="1.81395" stroke="#64686A" strokeWidth="1.6"/>
				</SVGMap>
				<SelectList
					style={{
						cursor: disableSelect ? 'not-allowed' : 'pointer',
						padding: imagerySource === IMAGERY_PROVIDER.NEARMAP ?'6px 24px 6px 40px':'6px 36px 6px 40px',
					}}
					value={imagerySource}
					onChange={handleChange}
					disabled={disableSelect}
				>
					<option value={IMAGERY_PROVIDER.SUNROOF}>Google Maps HD</option>
					<option value={IMAGERY_PROVIDER.NEARMAP}>Nearmap</option>
				</SelectList>
				<SVGDown className='arrowDown'
					width="11" height="7" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M1 1L5 5L9 1" stroke="#64686A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				</SVGDown>

			</DivMapping>
			<Popup
				contentStyle={{ width: '560px', borderRadius: '4px' }}
				open={open}
				closeOnDocumentClick={false}
				onClose={() => {
					if (open)
						setOpen(false);
				}}
			>
				<ModalPopup>
					<ModalcontentInfo>
						{message}
					</ModalcontentInfo>

					<ModalbuttonWrap>
						<ModalbuttonNo onClick={handleConfirmation}>
							No
						</ModalbuttonNo>
						<Modalbutton onClick={handleConfirmation}>
							Yes
						</Modalbutton>
					</ModalbuttonWrap>
				</ModalPopup>
			</Popup>
		</>
	);
}