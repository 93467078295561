import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { setMoveStage } from 'store/slices/ToolSlice';
import { ThemeProvider } from 'contexts/ThemeContext';

const queryClient = new QueryClient();

function App() {
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === 'Control') {
				dispatch(setMoveStage({ shouldMoveStage: true }));
			}
		};

		const handleKeyUp = () => {
			dispatch(setMoveStage({ shouldMoveStage: false }));
		};
		window.addEventListener('keydown', handleKeyPress);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [dispatch]);

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider>
				<Router>
					<div className='bodyWrapper'>
						<ToastContainer />
						<AppRoutes />
					</div>
				</Router>
			</ThemeProvider>
		</QueryClientProvider>
	);
}

export default App;
