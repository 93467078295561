import { NavigateOptions, To, useNavigate, useSearchParams } from 'react-router-dom';

const useNavigateWithQueryParams = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const navigateWithQueryParams = (to: To, options?: NavigateOptions) => {
		const queryParams = searchParams.toString();
		const routeWithQuery = `${to}${queryParams ? `?${queryParams}` : ''}`;
		navigate(routeWithQuery, options);
	};

	return { navigateWithQueryParams };
};

export default useNavigateWithQueryParams;
