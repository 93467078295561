import Button from 'components/Basic/Button';
import CustomTooltip from 'components/Basic/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setDeleteIndividualPanelsMode, togglePanelModal } from 'store/slices/PanelSlice';
import CancelDeletePanelModal from './CancelDeletePanelModal';
import useQueriesRunningInBackGround from 'hooks/useQueriesRunningInBackGround';

export default function DeletePanelBtn() {
	const dispatch = useDispatch();
	const { activePanelMode, deleteIndividualPanelMode, deletedPanels } = useSelector((state: RootState) => state.panelSlice.data);
	const { drawModeEnabled, hadTransition, currentActiveFacetEditMode } = useSelector((state: RootState) => state.toolNewPostions.data);
	const queriesRunningInBackground = useQueriesRunningInBackGround();
	const shouldDisable = queriesRunningInBackground || !!currentActiveFacetEditMode || 
	activePanelMode && !deleteIndividualPanelMode || drawModeEnabled || hadTransition;

	function handleClick() {
		if(deleteIndividualPanelMode && deletedPanels.length > 0){
			dispatch(togglePanelModal({targetModal: 'CANCEL_DELETE_PANEL', val:true}));
			return;
		}
	
		dispatch(setDeleteIndividualPanelsMode({ enabled: !deleteIndividualPanelMode }));
	}

	function handleCancelCLick(event: React.MouseEvent<HTMLButtonElement>){
		event.stopPropagation();
		if(deletedPanels.length > 0){
			dispatch(togglePanelModal({targetModal: 'CANCEL_DELETE_PANEL', val:true}));
			return;
		}

		dispatch(setDeleteIndividualPanelsMode({ enabled: false }));
	}

	return (
		<>
			<Button style={{
				background: `${deleteIndividualPanelMode ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,	
			}}
			id="delete-panel"
			className="btn-tools"
			disabled={shouldDisable}
			onClick={handleClick}
			// title="Delete Panel"
			>
				<svg width="20.25" height="22" viewBox="0 0 23 25" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.08543 22.6371H1V1H17.2278V7.76159" stroke="#64686A" strokeWidth="1.69284" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M11.5352 13.1152L21.9198 23.4998" stroke="#64686A" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
					<path d="M21.9199 13.1152L11.5353 23.4998" stroke="#64686A" strokeWidth="1.7" strokeLinecap="round" strokeLinejoin="round"/>
				</svg>

				{deleteIndividualPanelMode && (
					<label className='buttonLabel'
						style={{
							color: `${deleteIndividualPanelMode ? '#003CFF' : '#64686A'}`,
						}}>
						Delete Panel
						<span className='buttonIcon' onClick={handleCancelCLick}>	
							<svg width="11" height="11" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M5.71132 4.50043L8.73856 1.47233C8.82035 1.39334 8.88559 1.29884 8.93048 1.19436C8.97536 
								1.08988 8.99898 0.97751 8.99997 0.863803C9.00096 0.750096 8.97929 0.637331 8.93623 0.532087C8.89317 
								0.426843 8.82958 0.331229 8.74918 0.250823C8.66877 0.170416 8.57316 0.106829 8.46791 0.0637703C8.36267 
								0.0207117 8.2499 -0.000955751 8.1362 3.23334e-05C8.02249 0.00102042 7.91012 0.0246443 7.80564 0.0695253C7.70116 
								0.114406 7.60667 0.179646 7.52767 0.261437L4.49957 3.28868L1.47233 0.261437C1.39334 0.179646 1.29884 0.114406 
								1.19436 0.0695253C1.08988 0.0246443 0.97751 0.00102042 0.863803 3.23334e-05C0.750096 -0.000955751 0.637331 
								0.0207117 0.532087 0.0637703C0.426843 0.106829 0.331229 0.170416 0.250823 0.250823C0.170416 0.331229 0.106829 
								0.426843 0.0637703 0.532087C0.0207117 0.637331 -0.000955751 0.750096 3.23334e-05 0.863803C0.00102042 0.97751 
								0.0246443 1.08988 0.0695253 1.19436C0.114406 1.29884 0.179646 1.39334 0.261437 1.47233L3.28868 4.49957L0.261437 
								7.52767C0.179646 7.60667 0.114406 7.70116 0.0695253 7.80564C0.0246443 7.91012 0.00102042 8.02249 3.23334e-05 
								8.1362C-0.000955751 8.2499 0.0207117 8.36267 0.0637703 8.46791C0.106829 8.57316 0.170416 8.66877 0.250823 
								8.74918C0.331229 8.82958 0.426843 8.89317 0.532087 8.93623C0.637331 8.97929 0.750096 9.00096 0.863803 8.99997C0.97751 
								8.99898 1.08988 8.97536 1.19436 8.93048C1.29884 8.88559 1.39334 8.82035 1.47233 8.73856L4.49957 5.71132L7.52767 
								8.73856C7.60667 8.82035 7.70116 8.88559 7.80564 8.93048C7.91012 8.97536 8.02249 8.99898 8.1362 8.99997C8.2499 
								9.00096 8.36267 8.97929 8.46791 8.93623C8.57316 8.89317 8.66877 8.82958 8.74918 8.74918C8.82958 8.66877 8.89317 
								8.57316 8.93623 8.46791C8.97929 8.36267 9.00096 8.2499 8.99997 8.1362C8.99898 8.02249 8.97536 7.91012 8.93048 
								7.80564C8.88559 7.70116 8.82035 7.60667 8.73856 7.52767L5.71132 4.50043Z" fill="#64686A" />
							</svg>
						</span>
					</label>
				)}
			</Button>
			<CustomTooltip
				anchorSelect={'#delete-panel'}
				content={'Delete Panel'}
			/>
			<CancelDeletePanelModal />
		</>
	);
}