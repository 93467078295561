import styled from 'styled-components';

export const DivMapping = styled.div`
	display:flex;
	align-items:center;
	position: relative;
`;

export const SVGMap = styled.svg`
	position: absolute;
	padding-left:5px;
	color:#a1a1a1;
	margin-left: 9px;
	left: 0px;
`;

export const SelectList = styled.select`
	font-size: 0.938rem;
	background-color: #fff;
	color: #64686A;
	font-family: Inter;
	font-weight: 500;
	background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFF;
	border: 1px solid #C7CACF;
	box-shadow:none;
	border-radius:40px;
	height: 40px;
	appearance: none;
		&:focus-visible {
			outline: 1px solid #ccc;
		}
	@media (max-width: 1325px) {
		padding: 6px 34px 6px 36px;
		font-size: 0.856rem;
	}
	@media (max-width: 936px){
		height:34px;
		
	}
`;

export const SVGDown = styled.svg`
	position: absolute;
	color:#a1a1a1;
	right: 16px;
	pointer-events:none;
`;
