import { useLocation, useRoutes, } from 'react-router-dom';
import ROUTES from '../constants/routes';
import LoadRoofDesign from 'pages/LoadRoofDesign/LoadRoofDesign';
import LocationMap from 'pages/LocationMap/LocationMap';
import Home from 'pages/Home';
import PrivateRoute from './PrivateRoute';
import { useEffect } from 'react';
import useNavigateWithQueryParams from 'hooks/useNavigateWithQueryParams';

// todo make use of lazy components
// and seperate provate routes from public routes

// wrap private compoents like and check if you need to pass props too
{/* <PRivate></PRivate> */ }
export default function AppRoutes() {
	const location = useLocation();

	const { navigateWithQueryParams } = useNavigateWithQueryParams();

	useEffect(() => {
		const handleLoad = () => {
			const userIsOnHomePage = location.pathname === ROUTES.HOME;
			if (!userIsOnHomePage) {
				navigateWithQueryParams(ROUTES.HOME);
			}
			console.log(`Browser has fully loaded! current route ${location.pathname} query params${location.search}, we routed to home page ${!userIsOnHomePage}`,);
		};

		window.addEventListener('load', handleLoad);

		return () => {
			window.removeEventListener('load', handleLoad);
		};
	}, [location, navigateWithQueryParams]);

	return useRoutes([
		{
			path: ROUTES.HOME,
			element: <Home />,
		},
		{
			path: ROUTES.SHOW_DATA,
			element: <PrivateRoute><LoadRoofDesign /></PrivateRoute>
		},
		{
			path: ROUTES.SHOW_MAP,
			element: <PrivateRoute><LocationMap /></PrivateRoute>
		}
	]);
}