import { useKonvaScale } from 'hooks/useKonvaScale';
import { useCallback, useEffect, useState } from 'react';
import { Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { redoPanelState, undoPanelState } from 'store/slices/PanelSlice';

export default function Facets(){
	const [showLine, setShowLine] = useState(false);
	const { allRoofSegs} = useSelector((state: RootState) => state.roofData.data);
	const scale= useKonvaScale();
	const dispatch = useDispatch();

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if(event.ctrlKey && event.key === 'z') {
				dispatch(undoPanelState());
			} else if(event.ctrlKey && event.key === 'y') {
				dispatch(redoPanelState());
			} else if (event.ctrlKey && (event.key === 'r' ||  event.key === 'R')) {
				event.preventDefault();
				setShowLine(true);
			}
		};
		
		const handleKeyUp = (event: KeyboardEvent) => {
			if (!event.ctrlKey || !event.shiftKey) {
				setShowLine(false);					}
		};

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, []);

	const transformHullCoords = useCallback((segment: RasterRoofSegment) => {
		return segment.simplifiedHullCoords?.flat()?.map((point, index) => index % 2 === 0 ? point * scale.x : point * scale.y);
	},[scale]);

	if(!showLine) {
		return null;
	}

	return (
		<>
			{
				allRoofSegs.map(eachSeg => ( 
					(<Line
						key={eachSeg.id}
						stroke={'Red'}
						strokeWidth={2}
						points={transformHullCoords(eachSeg)}
					/>))
				)
			}
		</>
	);
}