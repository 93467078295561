import Select from 'react-select';
import styled from 'styled-components';

export const StyledSelect = styled(Select)`

&.custom-react-select-mode{
	.cus-select__control{
		border-color: #D6D6D6;
	}
	.cus-select__value-container{
		padding: 10px 8px !important;	
	}
	.cus-select__indicator-separator{
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		background-color: #D6D6D6;
	}
	.cus-select__dropdown-indicator{
    color: #595A5A;
		padding: 8px 10px;

		svg{
			height: 22px;
			width: 22px;
		}
  }
	
	.cus-select__single-value{
	  color: #2E384D;
	}
}
 
	width: 100%;
	// max-width: 350px;
	font-size: 1rem;
	margin-top: 2px;
	color: #2E384D;

	.custom-react-select-mode{
			.cus-select__value-container{
				padding: 8px 8px;
				.cus-select__single-value{
					line-height: 1.4;
				}
			}
	}
  
	.react-select__menu-list {
    max-height: 100px;
    width: 100%;

  }

`;

export const SelectorDiv = styled.div`
 
	width: 100%;
	// max-width: 250px;

	display: flex;
	flex-direction: column;
	justify-content:space-between;
	align-items:flex-start;
	gap: 0px;
	color: #2E384D;
	font-weight: 500;
	font-size: 0.95rem;
	margin-right: 0px;
	margin-bottom: 10px;
	white-space: nowrap;
	font-family: 'Inter';
	
	label{
		color: #2E384D;
		font-family: 'Inter';
		font-size: 1rem;
		font-weight: 500;
		margin-bottom:3px;
	}
`;